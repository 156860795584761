import React, { Component, Fragment } from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Spinner
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faCheck, faCheckCircle
		, faTimesCircle, faExclamationTriangle } from '@fortawesome/fontawesome-free-solid';
import Toast from '../components/Toast.js';
import { approveCorporateQuote, rejectCorporateQuote } from "../api/API.js";
import "./CorporateQuoteConfirm.css";


export default class CorporateQuoteConfirm extends Component {
	constructor(props) {
		super(props);
		// console.log("CorporateQuoteConfirm constructor", props);
		// parse query string
		let params = new URLSearchParams(props.location.search);
		this.state = {
			isLoading: true,
			toastMessage: "",
			toastVariant: "info",
			toastTime: 6000,
			showToast: false,
			showAddressForm: false,
			action: props.action,
			groupId: params.get("group_id"),
			hash: params.get("hash")
		}
		this.closeToastCallback = this.closeToastCallback.bind(this);
		this.approveCorporateQuoteCallback = this.approveCorporateQuoteCallback.bind(this);
		this.rejectCorporateQuoteCallback = this.rejectCorporateQuoteCallback.bind(this);
	}

	async componentDidMount() {
		/*
		* Call API to approve or reject.
		*/
		let params = {
			groupId: this.state.groupId,
			hash: this.state.hash
		};
		// console.log("API, params", params);
		switch(this.state.action) {
			case 'approve':
				approveCorporateQuote({ ...params, callback: this.approveCorporateQuoteCallback });
				break;
			case 'reject':
				rejectCorporateQuote({ ...params, callback: this.rejectCorporateQuoteCallback });
				break;
			default: break;
		}
	}

	componentWillUnmount() {

	}
	
	componentWillReceiveProps(props) {
		// console.log("CorporateQuoteConfirm componentWillReceiveProps", props);
	}
	
	approveCorporateQuoteCallback(response) {
		console.log("approveCorporateQuoteCallback", response);
		let responseMessage = "";
		let success = false;
		if(response.status === 201) {
			// this.makeToast("Quote approved successfully", "success");
			responseMessage = `Quote was approved successfully`;
			success = true;
		} else {
			// this.makeToast("Error approving quote", "failure");
			responseMessage = `Error trying to approve this quote:
				${response.response && response.response.data && Object.values(response.response.data).toString().replace(',', '')}`;
		}
		this.setState({ isLoading: false, responseMessage: responseMessage, success: success });
	}
	
	rejectCorporateQuoteCallback(response) {
		console.log("rejectCorporateQuoteCallback", response);
		let responseMessage = "";
		let success = false;
		if(response.status === 201) {
			// this.makeToast("Quote rejected successfully", "success");
			responseMessage = `Quote was rejected successfully`;
			success = true;
		} else {
			// this.makeToast("Error rejecting quote", "failure");
			responseMessage = `Error trying to reject this quote:
				${response.response && response.response.data && Object.values(response.response.data).toString().replace(',', '')}`;
		}
		this.setState({ isLoading: false, responseMessage: responseMessage, success: success });
	}

	makeToast(msg, variant) {
		//console.log("making toast");
		this.setState({ toastMessage: msg, toastVariant: variant, showToast: true });
	}

	closeToastCallback() {
		//console.log("closeToastCallback");
		this.setState({ showToast: false });
	}

	render() {
		return (
			this.state.isLoading ?
				<div id="CorporateQuoteConfirm">
					<Container className="loadingAnimation">
						<Row>
							<Col>
								<h4>
									Confirming quote, please wait
								</h4>
								<Spinner animation="grow" role="status">
									<span className="sr-only">Loading...</span>
								</Spinner>
							</Col>
						</Row>
					</Container>
				</div>
			:
				<div id="CorporateQuoteConfirm">
				{/*<Toast
						key={ this.state.toastMessage }
						show={ this.state.showToast }
						message={ this.state.toastMessage }
						variant={ this.state.toastVariant } 
						time={ this.state.toastTime }
				/>*/}
					<Row>
						<Col>
							<h1>Corporate Quote { this.state.groupId }</h1>
						</Col>
					</Row>
					<Row>
						<Col>
							<h3>
							{
								this.state.responseMessage
							}
							{
								this.state.success ?
									<FontAwesomeIcon icon={ faCheckCircle }
										className={ "faIcon " + (this.state.success ? "successIcon" : "failureIcon") } />
								:
									<FontAwesomeIcon icon={ faExclamationTriangle }
										className={ "faIcon " + (this.state.success ? "successIcon" : "failureIcon") } />
							}
							</h3>
						</Col>
					</Row>
				</div>
		);
	}
}
