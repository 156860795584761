import React, { PropTypes, Component, Fragment } from 'react';
import { Container, Row, Col, Form, Dropdown, DropdownButton, Spinner, Button } from 'react-bootstrap';
import Calendar from './Calendar/Calendar';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Appointments.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faHome, faCheck, faCalendarAlt } from '@fortawesome/fontawesome-free-solid';
import { getAppointmentsSchedule, getTimeslots, postAppointment, getStoreHoursForDateUsingDB } from '../api/API.js';
import Toast from '../components/Toast.js';
import InputMask from 'react-input-mask';


/*
* Service functions.
*/


// const handleChange = (e) => {
	//console.log("handleChange", e);
	//console.log(e.target.options[e.target.selectedIndex]);
// };


const capitalize = (str) => {
	return str.slice(0, 1).toUpperCase() + str.slice(1);
}


const mapLeadStatusToString = (status) => {
	return status.split("_").reduce( (acc, val) => {
		return acc + capitalize(val) + " ";
	}, "")
}

var selectedData = {};
var controls = { showTimeButtons: true };

export default class Appointments extends Component {

	constructor(props) {
		super(props)

		this.onDateChange = this.onDateChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.getTimeslotsCallback = this.getTimeslotsCallback.bind(this);
		this.timeslotChange = this.timeslotChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.submitAppointmentCallback = this.submitAppointmentCallback.bind(this);
		this.makeToast = this.makeToast.bind(this);
		this.closeToastCallback = this.closeToastCallback.bind(this);
		this.handleChange = this.handleChange.bind(this);

		let params = {
			id: props.shopid || (props.shops && props.shops[0] && props.shops[0].id),
			callback: this.getTimeslotsCallback
		};

		console.log("init state", controls);

		this.state = {
			...props,
			query: props.query,
			shopid: (controls && controls.shopid) || (props.query.shop_id && parseInt(props.query.shop_id)),
			// shopid: params.id,
			currentDate: props.date,
			currentTimeSlot: {},
			appointments: props.appointments,
			getAppointmentsSchedule: props.getAppointmentsSchedule,
			toastMessage: "",
			toastVariant: "info",
			showToast: false,
			disableSubmit: false,
			locationEditMode: false,
			// selectedData: { addressFirstLine: "", addressSecondLine: "", city: "", province: "AB", postalCode: "" },
			selectedData: { ...selectedData },
			shops: [
				{ id: 1, city: "Calgary", name: "We Come to You", address: "", img_url: "/img/i-location-home.png" },
				{ id: 2, city: "Calgary", name: "You Come to Us", address: "", img_url: "/img/i-location-shop.png" }
			],
			showTimeButtons: controls && controls.showTimeButtons,
			showAddressForm: controls && controls.showAddressForm
		}

		// if(params.id) getTimeslots(params);
	}

	componentDidMount() {
		// let params = {
			// shopid: this.state.shopid,
			// startDate: moment(this.state.startDate).format().split('T')[0],
			// endDate: moment(this.state.endDate).format().split('T')[0],
			// callback: this.state.getAppointmentsScheduleCallback
		// };

		// getAppointmentsSchedule(params);
	}

	componentDidUpdate(props) {
		// console.log("componentDidUpdate", props.shopid, props.appointments, this.state.shopid);
		// console.log("componentDidUpdate", Object.keys(props.appointments).length > 0 && Object.keys(props.appointments)[0]
			// , Object.keys(this.state.appointments).length > 0 && Object.keys(this.state.appointments)[0]);
		if((props.shopid && this.state.shopid // reset on shop change
			&& parseInt(props.shopid) !== parseInt(this.state.shopid))
				// or on date change
				|| ((Object.keys(props.appointments).length > 0 && Object.keys(props.appointments)[0])
				!==	(Object.keys(this.state.appointments).length > 0 && Object.keys(this.state.appointments)[0]))
		) {
			// this.setState({ appointments: props.appointments, shopid: props.shopid, query: props.query });
			// this.setState({ appointments: props.appointments, query: props.query });
		} else {
			// console.log("do nothing");
		}
	}

	getTimeslotsCallback(response) {
		// console.log("getTimeslotsCallback", response);
	}

	// const state = useSelector(state => state);

	/*
	* Change handlers.
	*/
	// handleDateChange(shopid, date, oldStart, oldEnd, view) {
	handleDateChange(shopid, date, oldStart, oldEnd, view) {
		// console.log("handleDateChange", shopid, date, oldStart, oldEnd, view);

		if(!shopid) {
			console.log("handleDateChange no shopid");
			this.makeToast("Select a location first", "failure");
			return;
		}

		if(moment(date).format("YYYY-MM-DD") < this.state.query.available) {
			this.makeToast("Earliest available date is " + this.state.query.available, "failure");
			return;
			// console.log("Selected date is before available date");
		}

		var m1, m2;
		var newStart, newEnd;
		newStart = newEnd = date;

		if(view === "week") {
			m1 = moment(date).startOf('isoWeek').format();
			m2 = moment(date).endOf('isoWeek').format();
		} else { // day view
			m1 = moment(newStart).format();
			m2 = moment(newEnd).format();
		}

		let params = {
			id: shopid,
			shopid: shopid,
			currentDate: date,
			date: moment(date).format().split('T')[0],
			startDate: m1.split('T')[0],
			endDate: m2.split('T')[0]
		}

		// persist data
		selectedData = this.state.selectedData;
		controls = {
			showAddressForm: this.state.showAddressForm,
			showTimeButtons: this.state.showTimeButtons,
			shopid: this.state.shopid
		};

		this.setState({ currentDate: date });
		// getTimeslots(params);
		this.state.getAppointmentsSchedule(params);
	}


	shopChange(shopid, date) {
		// let newId = e.target
				// && e.target.selectedOptions
				// && e.target.selectedOptions[0]
				// && e.target.selectedOptions[0].value;
		// console.log("shopChange", newId);
		// if(newId) this.setState({ shopid: newId });
		// else return;
		let startDate = moment(date).format().split('T')[0];
		let endDate = moment(date).add(1, 'days').format().split('T')[0];
		let showAddress = false;
		let showTime = true;

		if(shopid === 1) {
			showAddress = true;
			this.setState({ selectedData: {...selectedData, shopid: shopid }, shopid: shopid, showAddressForm: showAddress, showTimeButtons: showTime });
		} else {
			this.setState({ selectedData: {...selectedData, shopid: shopid }, shopid: shopid, showAddressForm: showAddress, showTimeButtons: showTime });
		}

		// persist data
		selectedData = this.state.selectedData;
		controls = {
			showAddressForm: showAddress,
			showTimeButtons: showTime,
			shopid: shopid
		};

		let params = {
			id: shopid,
			shopid: shopid,
			currentDate: date,
			date: moment(date).format().split('T')[0],
			startDate: moment(startDate).format().split('T')[0],
			endDate: moment(endDate).format().split('T')[0]
		}

		this.state.getAppointmentsSchedule(params);
	}

	dateRangeChange(date) {
		// if(type === "start")
			// this.setState({ startDate: date });
		// else if(type === "end")
			// this.setState({ endDate: date });
		// else
		this.setState({ appointmentDate: date });
	}

	onDateChange(shopid, date, oldStart, oldEnd, view) {
		// console.log("onDateChange ", shopid, date, oldStart, oldEnd, view);
		this.handleDateChange(shopid, date, oldStart, oldEnd, view);
	}


	timeslotChange(slot) {
		// console.log("timeslotChange", slot);
		// reject slot change if missing required data, indicated by presence of hour_start
		if(slot.hour_start) {
			// dispatch(changeTimeSlot(slot));
			this.setState({ currentTimeSlot: slot });
		}
	}

	makeToast(msg, variant, time, button, label) {
		// console.log("making toast", msg, variant, time, button);
		this.setState({ toastMessage: msg, toastVariant: variant, toastTime: time, toastButton: button, showToast: true, label: label });
	}
	// makeToast(msg, variant) {
		// console.log("making toast");
		// this.setState({ toastMessage: msg, toastVariant: variant, showToast: true });
	// }

	submitAppointmentCallback(e) {
		// console.log("submitAppointmentCallback", e);
		if(e.status === 201) {
			this.makeToast("Appointment created successfully!", "success"
				, 0,  () => { return (
								<Button variant="primary" href='https://boulevardautoglass.com'>
									<FontAwesomeIcon icon={ faHome } className="faIcon" />
									Back to Homepage
								</Button> )
							}
				, <div className="successMessage">Thank you for booking your appointment.<br/> You will receive an email shortly with your booking details.<br/>
					Please check your spam folder if it has not shown up.
					<img className="watermark" src='/img/logo-full.png'/>
					</div>);
			this.setState({ disableSubmit: true });
		} else {
			if(e.response && e.response.data && e.response.data.error)
				this.makeToast(e.response.data.error, "failure");
			else
				this.makeToast("There was an error submitting your request. Please try again later.", "failure");
		}
	}

	// handleSubmit(date, timeslotId, timeslots) {
	handleSubmit(date, timeslotId) {
		console.log("handleSubmit", date, timeslotId);

		// let slot = timeslots && timeslots.find( (val) => {
			// return parseInt(val.timeslot_id) === parseInt(timeslotId);
		// });

		// let installersAvailable = 0;

		// if(slot) {
			// slot.installers_available;
		// }

		if(moment(date).format("YYYY-MM-DD") < this.state.query.available) {
			console.log("Selected date is before available date");
		}

		if(date && timeslotId) {
			let params = {
				date: moment(date).format("YYYY-MM-DD")
				, hash: this.state.query.hash
				, quoteid: this.state.query.quote_id
				, package: this.state.query.extra_package
				, timeslotid: timeslotId
				, shopid: this.state.shopid
				, data: {}
				, callback: this.submitAppointmentCallback
			};

			// validate address change
			console.log("shopid", this.state.shopid);
			var data = {};
			if(parseInt(params.shopid) === 1) {
				if(!this.state.selectedData['addressFirstLine']
						|| !this.state.selectedData['city']
						|| !this.state.selectedData['postalCode']) {
					this.makeToast("Please fill out your address including the city and postal code", "info");
					return;
				} else {
					data.address1 = this.state.selectedData['addressFirstLine'];
					data.address2 = this.state.selectedData['addressSecondLine'];
					data.city = this.state.selectedData['city'];
					data.postalCode = this.state.selectedData['postalCode'];
					params.data = data;
				}

			} else {
				// no address needed
			}

			// console.log("handleSubmit params", params);

			// if(parseInt(params.shopid) === 1 || params.installers_available > 0) {
			// if(true) {
			postAppointment(params);
			// } else {
				// makeToast("Please select a timeslot with available installers", "failure");
			// }
		} else {
			this.makeToast("Select an appointment date", "failure");
		}
	}
	/**
	* Deprecated - for use with calendar
	*/
	// handleSubmit(e) {
		// console.log("handleSubmit", e);
		// let params = {
			// ...this.state.currentTimeSlot
			// , date: moment(this.state.currentTimeSlot.start).format("YYYY-MM-DD")
			// , hash: this.state.query.hash
			// , quoteid: this.state.query.quote_id
			// , quoteid: this.state.query.quote_id
			// , package: this.state.query.extra_package
			// , timeslotid: this.state.currentTimeSlot.timeslot_id
			// , shopid: this.state.shopid
			// , data: {}
			// , callback: this.submitAppointmentCallback
		// };
		// console.log("data", params);
		// if(parseInt(params.shopid) === 1 || params.installers_available > 0) {
			// postAppointment(params);
		// } else {
			// makeToast("Please select a timeslot with available installers", "failure");
		// }
	// }

	timeTo12HourString(time) {
		// console.log("timeTo12HourString", time);
		let timeString = "";
		if(time < 12) {
			timeString = `${time}:00 AM`;
		} else if(time === 12) {
			timeString = `${time}:00 PM`;
		} else {
			timeString = `${time % 12}:00 PM`;
		}
		return timeString;
	}

	closeToastCallback(e) {
		this.setState({ toastMessage: "", showToast: false });
	}

	handleChange(e) {
		console.log("handleChange", e.target.id, e.target.value);
		let temp = this.state.selectedData;
		temp[e.target.id] = e.target.value;
		this.setState({ selectedData: temp });
	}

	updateShop(shopid) {
		// console.log('updateShop', shopid);
		if(shopid === 1) {
			this.setState({ selectedData: {...selectedData, shopid: shopid }, shopid: shopid, showAddressForm: true, showTimeButtons: true });
		} else {
			this.setState({ selectedData: {...selectedData, shopid: shopid }, shopid: shopid, showAddressForm: false, showTimeButtons: true });
		}
		controls.shopid = shopid;
	}

	/* set selected form options */
	selectOption(fieldName, value) {
		console.log(fieldName, value);
		let data = this.state.selectedData;
		data[fieldName] = value;
		// console.log("selectOption", fieldName, data);
		this.setState({ selectedData: data });

		if (fieldName === "repairType") {
			this.repairTypeCallback(value);
		}
	}

	render() {
		// console.log("render appointments", this.state.appointments, this.state.currentDate);
		let timeslots = [];
		let currentDateString = moment(this.state.currentDate).format("YYYY-MM-DD");
		let currentDateSchedule = this.state.appointments
			&& this.state.appointments[currentDateString]
			&& {...this.state.appointments[currentDateString]};

		let now = new Date();

		if(currentDateSchedule) {
			timeslots = Object.keys(currentDateSchedule).map( (val, i) => {
				let slot = currentDateSchedule[val];
				// add in fields required for calendar
				let start = moment(this.state.currentDate).toDate();
				start.setHours(slot.hour_start, 0, 0);
				start = new moment(start).toDate();

				let end = moment(this.state.currentDate).toDate();
				end.setHours(slot.hour_end, 0, 0);
				end = new moment(end).toDate();

				slot.start = start;
				slot.end = end;
				slot.title =  this.state.shopid && parseInt(this.state.shopid) === 1 ?
					<div>
						{/*<h5>Installers { `${slot.installers_available} / ${slot.installers_total}` } </h5>*/}
						<h5>Installer arrival window</h5>
					</div>
				:
					<div>
						<h5>{ `${slot.installers_available}` } Available Appointments </h5>
					</div>;

				slot.description = "Available shop time";
				return slot;
			});

			/*
			* Remove timeslots from the past, or before the available date
			* Also, remove All Day timeslot on this retail page.
			*/
			timeslots = timeslots.filter( val => {
				// console.log("timeslot val", val);
				if(val.start > now
						&& moment(val.start).format("YYYY-MM-DD") >= this.state.query.available
						&& val.timeslot_label !== "ALL DAY"
						&& ((this.state.shopid === 1 && val.timeslot_id >= 14) || (this.state.shopid === 2))) { // deprecated timeslots
					return val;
				}
			})

			var count = 0;
			var centerScheduleButtons = false;
			/*
			* Invalidate timeslots based on day of week.
			* This assumes knowledge of the AM timeslot ending at 1pm, or hour_end of 13
			*/
			timeslots = timeslots.map( val => {
				let dow = parseInt(moment(this.state.currentDate).day());
				let isValid = ((val.timeslot_day_of_week >= 1 && val.timeslot_day_of_week <= 5) &&
							  (dow >= 1 && dow <= 5))
								|| (val.timeslot_day_of_week === 0 && dow === 0)
								|| (val.timeslot_day_of_week === 6 && dow === 6);
				val.is_valid = isValid;
				return val;
			});

			//
			timeslots.forEach( val => {
				if(val.is_valid)
					count++;
			});

			// centerScheduleButtons = !(count % 3 == 0);
			centerScheduleButtons = count < 3;
			// console.log("count", count);
			// console.log("centerScheduleButtons", centerScheduleButtons);

			// Also, remove deprecated timeslots from mobile
			// console.log(timeslots, this.state.shopid);
			// if(parseInt(this.state.shopid) === 1) {
				// timeslots = timeslots.filter( val => {
					// return val.timeslot_id === 14 ||  val.timeslot_id === 15;
				// });
			// }
		}

		// console.log(timeslots);

		let props = {
			shops: this.state.shops,
			shopid: this.state.shopid,
			currentDate: this.state.currentDate,
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			events: timeslots,
			views: ['day'],
			view: 'day',
			mode: "normal",
			timeslots: timeslots,
			currentTimeSlot: this.state.currentTimeSlot,
			locationEditMode: this.state.locationEditMode,
			package: this.state.query.extra_package,
			available: this.state.query.available,
			// timeSlots: [
				// {
					// id: 1,
					// title: "test",
					// desc: "test desc",
					// start: start,
					// end: end,
					// hour_start: 12,
					// hour_end: 13
				// }
			// ]
		};

		let currentShopName = Object.values(props.shops).find( val => { return val.id === parseInt(props.shopid) } );
		// console.log(props.shops, props.shopid, currentShopName);
		if(currentShopName) currentShopName = currentShopName.name;
		// let toastButton = this.state.button;

		// console.log("**", props.shopid);
		// console.log("**", this.state.shopid);
		// console.log("**", parseInt(this.state.shopid) === 2 && this.state.showTimeButtons);
		// console.log("** timeslots", this.state.timeslots);
		// console.log("** props timeslots", props.timeslots);
		// console.log("** local timeslots", timeslots);

		return (
			props.shops ?
				<Container id="appointmentsContainer" className="appointments" key={this.state.shopid}>
					<Toast key={ this.state.toastMessage }
						show={ this.state.showToast }
						message={ this.state.toastMessage }
						variant={ this.state.toastVariant }
						callback={ this.closeToastCallback }
						button={ this.state.toastButton }
						time={ this.state.toastTime }
						label={ this.state.label }
						/>
					<div className="controls">
						<Row>
							<Col sm={6} className="locationSelect">
								{/*<Form>
									<Form.Group controlId="shop">
										<Form.Label>
											Location
										</Form.Label>
										{
											props.locationEditMode ?
												<Form.Control as="select"
													value={ props.shopid }
													onChange={ (e) => {
														e.persist();
														this.shopChange(e
															, props.currentDate
															, props.startDate
															, props.endDate);
												} }>
												{
													Object.values(props.shops).map((val, i) => {
														return <option key={ i } value={ val.id } >{ val.name }</option>
													})
												}
												</Form.Control>
											: (<>
												<h5>
													{ currentShopName }
												</h5>
												<Button variant="secondary" size="md" onClick={ () => this.setState({ locationEditMode: true }) }>Change</Button>
											</>)
										}
									</Form.Group>
								</Form>*/}
								<div className="fieldset-indent">
									<div className="section-heading">Locations</div>
									<ul className="list">
										{this.state.shops && this.state.shops.map((val, i) => {
											//console.log(val);
											return (
												<Fragment key={ i }>
													{val.badge ?
														<li className={"col-sm-6 list-item location-option"}>
															<div className="w-100 h-100" style={{'display':'flex'}}>
																<img className="img-fluid m-auto" src={val.img_url} alt={val.name} />
															</div>
														</li>
													:
														<li className={"col-sm-6 list-item location-option" + (this.state.shopid === val.id ? " selected" : "")}>
															<label htmlFor={"shop_"+i}>
																<div className="bg-image" style={{ 'backgroundImage': 'url('+val.img_url+')' }}>
																	<img src="/img/blank.png" width="200" height="150" alt={val.name} />
																</div>
																<input type="radio" data-shop={ val.name } name={"LeadForm[shop_id]"} id={"shop_"+i} value={i} onClick={ () => {
																	// this.selectOption('shopid', val.id);
																	// this.updateShop(val.id);
																	this.shopChange(val.id, this.state.currentDate);
																} }/>
																<span><strong>{ val.city }</strong><br/>{ val.name }<br/>{ val.address }</span>
																<div className="selected"><FontAwesomeIcon icon={faCheck} /></div>
															</label>
														</li>
													}
												</Fragment>
											)
										})}
									</ul>
								</div>
							</Col>
							<Col sm={6} className="dateSelectors">
								<div className="section-heading">Appointment Date</div>
								<div className="calendarIcon">
									<FontAwesomeIcon icon={ faCalendarAlt }/>
								</div>
								<Form.Group controlId="appointmentDate">
								{/*<Form.Label>Appointment Date</Form.Label>*/}
									<DatePicker
										selected={ props.appointmentDate || props.currentDate }
										onChange={ (date) => {
											this.handleDateChange(this.state.shopid, date, props.startDate, props.endDate, "day")
										} }
										placeholderText="Select a date"
										className="form-control"
									/>
								</Form.Group>
								<h5>
									First available appointment or pick another future date
								</h5>
								{/*<Form.Group controlId="endDate">
									<Form.Label>End Date</Form.Label>
									<DatePicker
										selected={ props.endDate || props.currentDate }
										onChange={ (date) => { this.dateRangeChange(date, "end") } }
										placeholderText="Select a date"
										className="form-control"
									/>
								</Form.Group>*/}
							</Col>
						</Row>
						{/*<Row>
							<Col className="errors">{/* error messages
								{
									props.errors ? props.errors.map((val, i) => {
										//console.log(val);
										//console.log(val.error);
										//console.log(val.error.data);
										return (
											<span key={ i }>{ val.error && val.error.data && val.error.data.error }</span>
										)
									}) : ""
								}
							</Col>
						</Row>*/}
						{/*<Row>
							<Col sm={5} className="timeslotAppointmentDetails">
							{
								props.currentTimeSlot ? (<>
									{/*<Row>
										<Col>
											<h4>
											{ props.shopid && parseInt(props.shopid) === 1 ? "Installer Arrival Window" : "Selected Appointment Time" }
											</h4>
										</Col>
									</Row>
									<Row className="dataRow">
										<Col sm={4}>
											Title
										</Col>
										<Col sm={8}>
											{ props.currentTimeSlot['title'] }
										</Col>
									</Row>*/}
									{/*<Row className="dataRow">
										<Col sm={4}>
											{ props.currentTimeSlot['description'] }
										</Col>
										<Col sm={8}>
											&nbsp;
										</Col>
									</Row>*/}
									{/*<Row className="dataRow">
										<Col sm={4}>
											Date
										</Col>
										<Col sm={8}>
											{ props.currentTimeSlot['start'] && props.currentTimeSlot['start'].toDateString() }
										</Col>
									</Row>
									<Row className="dataRow">
										<Col sm={4}>
											Start Time
										</Col>
										<Col sm={8}>
										{
											props.currentTimeSlot && props.currentTimeSlot.hour_start ?
												`${this.timeTo12HourString(props.currentTimeSlot['hour_start'])}`
											: ""
										}
										</Col>
									</Row>

									<hr/>
									<Row>
										<Col className="createButton">
										{
											props.createButton ? props.createButton(props.shopid) : ""
										}
										</Col>
									</Row>
								</>) : ""
							}
							</Col>
							<Col sm={4} className="packageCol">
							{
								props.package ? (<>
									<div className="packageLabel">Package</div>
									<h3 className="packageName">{ props.package }</h3>
								</>) : ""
							}
							</Col>
							<Col sm={3}>
							{/*
								props.currentTimeSlot && props.currentTimeSlot.timeslot_id ?
									<Row>
										<Col className="submitButton">
											<Button className="blueButton" disabled={ this.state.disableSubmit } onClick={ this.handleSubmit } >
												Submit
											</Button>
										</Col>
									</Row>
								: ""
							*/}
									{/*<Row>
											<Col>
												<h5>Select an open timeslot</h5>
											</Col>
									</Row>
							</Col>
						</Row>*/}
					</div>
					<Row className="appointmentBlurb">
						<Col>
							<h4 className="mb-4 text-center">
							{
								parseInt(this.state.shopid) === 1 && this.state.showAddressForm ? <div>
									<span>Pick a time for our technicians to come to you!</span><br/>
									<span>A $35 fee will be added to your quote for a mobile appointment</span>
									<Form className="mx-4 mt-2 addressForm">
										<Form.Group controlId="addressFirstLine">
											<Form.Control type="text" placeholder="Street address" value={ this.state.selectedData['addressFirstLine'] } onChange={ this.handleChange } />
										</Form.Group>
										<Form.Group controlId="addressSecondLine">
											<Form.Control type="text" placeholder="Address line 2 (optional)" value={ this.state.selectedData['addressSecondLine'] } onChange={ this.handleChange } />
										</Form.Group>
										<Form.Group controlId="city">
											<Form.Control as="select" onChange={ this.handleChange } defaultValue={ this.state.selectedData['city'] }>
												<option value={null}>City</option>
												<option value="Calgary">Calgary</option>
												<option value="Airdrie">Airdrie</option>
												<option value="Okotoks">Okotoks</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="province">
											<Form.Control as="select" onChange={ this.handleChange } disabled>
												<option value="AB">Alberta</option>
												<option value="BC">British Columbia</option>
												<option value="MB">Manitoba</option>
												<option value="NB">New Brunswick</option>
												<option value="NL">Newfoundland and Labrador</option>
												<option value="NS">Nova Scotia</option>
												<option value="ON">Ontario</option>
												<option value="PE">Prince Edward Island</option>
												<option value="QC">Quebec</option>
												<option value="SK">Saskatchewan</option>
												<option value="NT">Northwest Territories</option>
												<option value="NU">Nunavut</option>
												<option value="YT">Yukon</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="postalCode">
											<InputMask id="postalCode" className="form-control" placeholder="Postal Code" mask="*** ***" value={ this.state.selectedData.postalCode } onChange={ this.handleChange } />
										</Form.Group>
									</Form>
								</div> : <div> {
									this.state.shopid === 2 && this.state.showTimeButtons ? (<div>
											All of Boulevard's AutoGlass Shop appointments are 2 hours.<br/>
											<div className="text-left w-50 shopDescription">
												30 min - Pre Inspection and Prep<br/>
												60 min - Windshield Installation<br/>
												30 min - Post Inspection and Cleanup<br/>
											</div>
											{/*}<div className="text-left shopFeeBlurb">
												A $35 shop fee will be added to your quote
											</div>*/}
										</div>
									) : (
										<div>
											Select a location for your appointment
										</div>
									)
								} </div>
							}
							</h4>
						</Col>
					</Row>
					<Row>
						<Col className="calendarContainer">
						{/*
							<Calendar
								shopid={ this.state.shopid }
								token={ props.token }
								onSelecting={ () => false }
								onDateChange={ this.onDateChange }
								onSelect={ this.timeslotChange }
								date={ props.currentDate }
								startDate={ props.startDate }
								endDate={ props.endDate }
								events={ props.events }
								onView={ props.onView }
								view={ props.view }
								views={ ['day'] }
								mode={ props.mode }
							/>
						*/}
						</Col>
					</Row>
					<Row className={ "scheduleButtons " + (centerScheduleButtons ? " justify-content-center" : "") }>
					{ count > 0 ?
						props.timeslots && props.timeslots.map( (val, i) => {
							// console.log("val", val);
							return val.timeslot_is_visible && val.is_valid ? (
								<Col sm={4} key={ 'timeslot-' + val.timeslot_id }>
									{
										this.state.shopid === 1 ? <>
											<Button className="blueButton" onClick={ (e) => {
													this.handleSubmit(props.appointmentDate || props.currentDate, val.timeslot_id);
												} }
											>
											{
												val.timeslot_label
											}
											</Button>
											<h5>Technican Arrival Window</h5>
											<h3>
											{
												getStoreHoursForDateUsingDB(val)
											}
											</h3>
										</> : <>
											<Button className="blueButton" onClick={ (e) => {
													this.handleSubmit(props.appointmentDate || props.currentDate, val.timeslot_id);
												} }
											>
											<h5>Appointment Time</h5>
											<h4 className="timeslotLabel">
											{
												getStoreHoursForDateUsingDB(val)
											}
											</h4>
											</Button>
										</>
									}
								</Col>
							) : ""
						} )
						: controls.showTimeButtons ?
								<Col>
									<h2>No slots are available for same-day appointment at this time</h2>
									<h4>Please choose another date</h4>
								</Col>
							: ""
					}
					</Row>
					{/*

														{
										this.state.shopid === 1 ?
											<span>{ val.timeslot_label }</span>
										:
											<h5>Available</h5>
									}


						parseInt(this.state.shopid) === 1 && this.state.showTimeButtons ?
							<Row className="scheduleButtons">
								<Col sm={4}>
									<Button className="blueButton" onClick={ () => {
											// this.timeslotChange({ hour_start: 8, timeslot_id: 14 });
											this.handleSubmit(this.state.currentDate, 14);
										} }
									>
										AM
									</Button>
									<h5>Technican Arrival Window</h5>
									<h3>
										8:00AM - 1:00PM
									</h3>
								</Col>
								<Col sm={4}>
									<Button className="blueButton" onClick={ () => {
											// this.timeslotChange({ hour_start: 8, timeslot_id: 14 });
											this.handleSubmit(this.state.currentDate, 14);
										} }
									>
										All Day
									</Button>
									<h5>Technican Arrival Window</h5>
									<h3>
										8:00AM - 5:00PM
									</h3>
								</Col>
								<Col sm={4}>
									<Button className="blueButton" onClick={ () => {
											// this.timeslotChange({ hour_start: 13, timeslot_id: 15 });
											this.handleSubmit(this.state.currentDate, 15);
										} }
									>
										PM
									</Button>
									<h5>Technican Arrival Window</h5>
									<h3>
										1:00PM - 6:00PM
									</h3>
								</Col>
							</Row>
						:  parseInt(this.state.shopid) === 2 && this.state.showTimeButtons ?
								<Row className="shopScheduleButtons">
								{
									props.timeslots && props.timeslots.map( (val) => {
										// console.log("slot", val);
										return (
											<Col sm={4}>
												<Button className="blueButton" disabled={ val.installers_available <= 0 } onClick={ () => {
														this.handleSubmit(this.state.currentDate, val.timeslot_id, props.timeslots);
													} }
												>
												<h5>Appointment Time</h5>
												{
													this.timeTo12HourString(val.hour_start)
												}
												</Button>
											</Col>
										)
									})
								}
								</Row>
							: ""
					*/}
					<hr/>
				</Container>
			:
				<Container className="appointments">{/* loading animation */}
					<Row>
						<Col>
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
							<span>Scheduler</span>
						</Col>
					</Row>
				</Container>
		)
	}
}
