import React, { Component, Fragment } from 'react';

import {
	Row,
	Col,
	// Nav,
	Form,
	Button
} from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faCheck, faCheckCircle, faTimesCircle } from '@fortawesome/fontawesome-free-solid';
import Toast from '../components/Toast.js';
import InputMask from 'react-input-mask';
import Expand from 'react-expand-animated';

import { getShops, getVehicleYears, getVehicleMakes, getVehicleModels, getVehicleTrims
		, createLead, getUsersurvey, postUsersurvey } from "../api/API.js";
import "./Survey.css";


export default class Survey extends Component {

	constructor(props) {
		super(props);

		this.state = {
			questionOptions: [
				{ name: "Yes", value: "Yes", img_url: "https://via.placeholder.com/400x300.png?text=Yes+Image" },
				{ name: "No", value: "No", img_url: "https://via.placeholder.com/400x300.png?text=No+Image" }
			],
			contactMethods: [
				{ name: "Email", icon: faEnvelope, img_url: "https://via.placeholder.com/400x300.png?text=Email+Image" },
				{ name: "Phone", icon: faPhone, img_url: "https://via.placeholder.com/400x300.png?text=Phone+Image" }
			],
			contactOptions: [
				{ name: "Yes", caption: "Yes, subscribe me", icon: faCheckCircle, img_url: "https://via.placeholder.com/400x300.png?text=Yes" },
				{ name: "No", caption: "No thank you", icon: faTimesCircle, img_url: "https://via.placeholder.com/400x300.png?text=No" }
			],
			repairTypes: [
				{ name: "New Windshield", value: "new windshield", img_url: "https://via.placeholder.com/400x300.png?text=New+Windshield+Image" },
				{ name: "Rock Chip Repair", value: "rock chip", img_url: "https://via.placeholder.com/400x300.png?text=Rock+Chip+Repair+Image" },
				{ name: "Side Mirror", value: "side mirror", img_url: "https://via.placeholder.com/400x300.png?text=Side+Mirror+Image" },
				{ name: "Back Glass", value: "back glass", img_url: "https://via.placeholder.com/400x300.png?text=Back+Glass+Image" },
				{ name: "Side Window", value: "side window", img_url: "https://via.placeholder.com/400x300.png?text=Side+Window+Image" }
			],
			shops: [
				{ id: 1, city: "Calgary", name: "Come to You", address: "123 test ave.", img_url: "https://cdn.shoplightspeed.com/shops/605079/files/16211681/345x345x1/le-toy-van-le-toy-van-dolly-ice-cream-van.jpg" },
				{ id: 2, city: "Calgary", name: "Calgary Shop", address: "123 test ave.", img_url: "https://img.bricklink.com/ItemImage/SL/4886-1.png" }
			],
			// initialize controlled form inputs to stop warnings
			selectedData: { addressFirstLine: "", addressSecondLine: "", city: "", province: "AB", postalCode: ""
				, firstName: "", lastName: "", email: "", phone: "", question1: "", question2: "", question3: ""
				, responses: { } },
			isLoading: false,
			// toast related fields
			toastMessage: "",
			toastVariant: "info",
			showToast: false,
			showAddressForm: false,
		}

		this.usersurveyCallback = this.usersurveyCallback.bind(this);
		// this.usersurveyResponsesCallback = this.usersurveyResponsesCallback.bind(this);
		this.closeToastCallback = this.closeToastCallback.bind(this)

		// event handlers
		this.handleChange = this.handleChange.bind(this);
	}

	async componentDidMount() {
		//this.setState({ isLoading: true});
		//getShops(this.shopsDataCallback);
		//getVehicleYears(this.vehicleYearsCallback);
		//getVehicleMakes(this.vehicleMakesCallback);
		/*await getVehicleModels(this.vehicleModelsCallback);
		await getVehicleTrims(this.vehicleTrimsCallback);*/

		getUsersurvey(this.usersurveyCallback);
		// getUsersurveyResponses(this.usersurveyResponsesCallback);

		if (this.props.match) {
			const { leadId } = this.props.match.params;
		}
	}

	componentWillUnmount() {

	}


	usersurveyCallback(data) {
		console.log("usersurveyCallback", data);
		this.setState({ usersurvey: data });
	}

	/*usersurveyResponsesCallback(data) {
		console.log("usersurveyResponsesCallback", data);
		this.setState({ usersurveyResponses: data });
	}*/


	/*
	* Select a question response. Store in associative object, i.e. 1 response per question.
	*/
	selectQuestionOption(questionId, optionId, notes) {
		let data = this.state.selectedData;
		let responses = data.responses;
		let obj = { question_id: questionId, option_id: optionId, notes: notes };
		responses['' + questionId] = obj;
		this.setState({ selectedData: data });
	}


	/* set selected form options/ fields*/
	selectOption(fieldName, value) {
		let data = this.state.selectedData;
		data[fieldName] = value;
		this.setState({ selectedData: data });

		if (fieldName === "repairType") {
			this.repairTypeCallback(value);
		}
	}

	/*
		The keys of the fields to reset.
	*/
	resetField(fields) {
		let form = document.getElementById('vehicleForm');
		console.log("form", form);
		console.log("fields", fields);
		if(form) {
			fields && fields.forEach(val => {
				form[val].selectedIndex = 0;
			});
		} else {
			console.log("resetField: form not loaded");
		}
	}

	/* change handlers */

	/* map value to selectedData[id] using event data */
	handleChange(e) {
		// console.log("handleChange", e.target.id, e.target.value);
		let temp = this.state.selectedData;
		temp[e.target.id] = e.target.value;
		this.setState({ selectedData: temp });
	}


	/*
	* Submit the survey responses to the API.
	* Note that
	*/
	handleSubmitSurvey(e) {
		//console.log("submit");
		e.preventDefault();
		//validate
		console.log(this.state.selectedData);

		const submitSurveyCallback = (response) => {
			console.log("submitSurveyCallback", response);


			window.setTimeout(function() {
				handleSubmit.makeToast("Your survey has been submitted. Thank you!", "info");
			}, 1000);
		}

		let data = {
			lead_id: 7,
			comments: "test",
			responses: Object.values(this.state.selectedData.responses)
		}

		postUsersurvey({ data: data, callback: submitSurveyCallback });

		//console.log(this.state.selectedData);

		let handleSubmit = this;

	}

	createLeadCallback(success, data) {
		console.log("alertCreateLead", success);
		if (success) {
			this.makeToast("Your request has been submitted.", "info");
			// window.location.reload();
		} else {
			this.makeToast("There was an error submitting your request. Please try again later.", "info");
		}
	}

	boundFunction = this.createLeadCallback.bind(this);


	/* check for "come to me" option and display address form */
	updateShop(shopName) {
		//console.log('updateShop', shopName);
		if(shopName === "come to you")
			this.setState({ showAddressForm: true });
		else
			this.setState({ showAddressForm: false });
	}

	makeToast(msg, variant) {
		//console.log("making toast");
		this.setState({ toastMessage: msg, toastVariant: variant, showToast: true });
	}

	closeToastCallback() {
		//console.log("closeToastCallback");
		this.setState({ showToast: false });
	}

	render() {
		console.log("**", this.state);
		/*const headingProps = {
			heading: "Get a quote",
			subheading: "Request a quote in 30 seconds or less.",
			bulletpoints: ["We guarantee our work for life", "We specialize at this too"]
		};*/
		//console.log("this.state.showToast", this.state.showToast);
		const expandTransition = ["height", "opacity"];

		return (
			<div id="SurveyForm">
				<Toast show={ this.state.showToast } callback={ this.closeToastCallback }
					message={ this.state.toastMessage } variant={ this.state.toastVariant } />
				<Row>
					<Col className="mt-4">
						<h1>Take this 1 min survey to enter to win a free windshield replacement (up to $500)</h1>
					</Col>
				</Row>
				{
					this.state.usersurvey && this.state.usersurvey.map( (val, i) => {
						return (
							<Row key={i}>
								<Col id="question1">
									<div className="section-heading">Question { i + 1}</div>
									<div className="section-subheading">{ val.text }</div>
									<div className="section-questions">
										<Row>
											{ this.buildQuestionTiles(val.options, val.id) }
										</Row>
									</div>
								</Col>
							</Row>
						)
					})
				}

				<Row>{/* comments etc. */}
					<Col>
						{/*<div className="section-heading">Any other features or comments:</div>*/}
						<div className="mt-4 mb-5">
							{/*<Form.Group controlId="comments">
								<Form.Control as="textarea" rows="6" value={ this.state.selectedData['comments'] } onChange={ this.handleChange } />
							</Form.Group>*/}
							<Button variant="primary" onClick={ (e) => this.handleSubmitSurvey(e) }>Submit Survey</Button>
						</div>
					</Col>
				</Row>
			</div>
		);
	}


	buildQuestionTiles(options, qnum) {
		return (
			<div className="fieldset-indent">
				<ul className="list">
					{options && options.map((val, i) => {
						//console.log(val);
						return (
							<li key={ i } className={"list-item question"+qnum+"-option" + (this.state.selectedData['question'+qnum] === val.id ? " selected" : "")}>
								<label htmlFor={"question"+qnum+"_"+i}>
									<div className="bg-image" style={{ 'backgroundImage': 'url('+val.img_url+')' }}>
										<img src="/img/blank.png" width="200" height="150" alt={val.name} />
									</div>
									<input type="radio" data-shop={ val.name } name={"LeadForm[question"+qnum+"]"} id={"question"+qnum+"_"+i} value={i} onClick={ () => {
										this.selectOption('question'+qnum, val.id); // for local presentation
										this.selectQuestionOption(qnum, val.id); // for storage of response
									} } />
									<span>{ val.text }</span>
									<div className="selected"><FontAwesomeIcon icon={faCheck} /></div>
								</label>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}
}
