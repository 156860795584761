import React, { Component, Fragment } from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";

import {
	Container,
	Row,
	Col,
	Nav
} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

import Home from './containers/Home.js';
import Services from './containers/Services.js';
import About from './containers/About.js';
import Locations from './containers/Locations.js';
import Quote from './containers/Quote.js';
import Survey from './containers/Survey.js';
import Schedule from './containers/Schedule.js';
import CorporateQuoteConfirm from './containers/CorporateQuoteConfirm.js';
import './App.css';
import './style/master.css';

/* google tag manager */
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
	gtmId: 'GTM-WT96H2Q'
}
TagManager.initialize(tagManagerArgs);

/* navigation component displayed on most pages */
function Navbar(props) {
	return (
		<Nav>
			<Nav.Item>
				<Nav.Link href="/home">Home</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="/services">Services</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="/about">About Us</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="/locations">Locations</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="/quote">Get a Quote</Nav.Link>
			</Nav.Item>
		</Nav>
	);
}

function Header(props) {
	return (<Fragment>

<div id="header">
	<div className="header_outer">
		<div className="container">
			<div className="row">
				<div className="col-sm-6">
					<div id="social_icon"></div>
				</div>
				<div className="col-sm-6">
					<div className="hdr_right">
						<p className="phn_num">Call (587) 391-4030</p>
						<a className="get_quote" href="http://boulevardautoglass.com/contact/"><img src="http://boulevardautoglass.com/wp-content/uploads/2020/04/1rx.png"/></a>
					</div>
				</div>
			</div>
			<div className="row menu_sec">
				<div className="col-sm-12">

					<div id="logo">
						<a href="http://boulevardautoglass.com/" rel="home"><img src="http://boulevardautoglass.com/wp-content/themes/boulevard/assets/images/logo.png"/></a>
					</div>

					<div className="menu">
						<div className="navbar navbar-static-top bs-docs-nav" id="top" role="banner">
							<div className="navbar-header">
								<button className="navbar-toggle collapsed" type="button" data-toggle="collapse" data-target="#bs-navbar" aria-controls="bs-navbar" aria-expanded="false">
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								</button>
								<a href="#" className="navbar-brand visible-xs">Navigation</a>
							</div>
							<nav id="bs-navbar" className="collapse navbar-collapse">
								<div className="menu-top-menu-container">
									<ul id="menu-top-menu" className="nav navbar-nav">
										<li id="menu-item-24" className="menu-item menu-item-home menu-item-24"><a href="http://boulevardautoglass.com/">Home</a></li>
										<li id="menu-item-25" className="menu-item menu-item-25"><a href="http://boulevardautoglass.com/about/">About</a></li>
										<li id="menu-item-159" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-159"><a href="#">Services</a>
											<ul className="sub-menu">
												<li id="menu-item-158" className="menu-item current-menu-item page_item page-item-127 current_page_item menu-item-158"><a href="http://boulevardautoglass.com/services/windshield-replacement/" aria-current="page">Windshield Replacement</a></li>
												<li id="menu-item-156" className="menu-item menu-item-156"><a href="http://boulevardautoglass.com/services/additional-glass-replacement/">Additional Glass Replacement</a></li>
												<li id="menu-item-155" className="menu-item menu-item-155"><a href="http://boulevardautoglass.com/services/mobile-service/">Mobile Service</a></li>
												<li id="menu-item-154" className="menu-item menu-item-154"><a href="http://boulevardautoglass.com/services/calibration/">Calibration</a></li>
												<li id="menu-item-157" className="menu-item menu-item-157"><a href="http://boulevardautoglass.com/services/rock-chip-repair/">Rock Chip Repair</a></li>
												<li id="menu-item-29" className="menu-item menu-item-29"><a href="http://boulevardautoglass.com/fleet-services/">Fleet Services</a></li>
											</ul>
										</li>
										<li id="menu-item-27" className="menu1 menu-item menu-item-27"><a href="http://boulevardautoglass.com/faqs/">FAQ’S</a></li>
										<li id="menu-item-28" className="menu2 menu-item menu-item-28"><a href="http://boulevardautoglass.com/articles/">Articles</a></li>
										<li id="menu-item-189" className="menu-item menu-item-189"><a href="http://boulevardautoglass.com/employment-opportunities/">Employment</a></li>
										<li id="menu-item-30" className="last menu-item menu-item-30"><a href="http://boulevardautoglass.com/contact/">Contact</a></li>
									</ul>
								</div>
							</nav>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>

	</Fragment>)
}

/* site-wide footer */
function Footer(props) {
	return (
		<Fragment>
			<div id="footer_btm">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="disclaimer">By submitting this form, you understand that your consent for Boulevard Autoglass to send you electronic messages to facilitate your request, including: quotes for requested services, invitations to schedule quoted services, follow up messages to facilitate your quote or scheduled services, and messages inviting you to provide feedback to Boulevard Autoglass. Your information will be kept private and will not be shared with third parties. For more information, please read our <a href="https://boulevardautoglass.com/privacy">Privacy Policy</a>.</div>
						</div>
					</div>
				</div>
			</div>

			<div id="ftr_copy">
				<div className="container">
					<div className="row" id="copy">
						<div className="col-sm-6 text-left">
							<p><a href="https://boulevardautoglass.com/privacy">Privacy Policy</a></p>
						</div>
						<div className="col-sm-6 text-right">
							<p>© Copyright 2020 Boulevard Auto Glass</p>
						</div>
					</div>
				</div>
			</div>

		</Fragment>
	);
}

function App() {
	return (
		<Router>
			<div id="page_outer" className="my-blue-bleed">
				{/*<Header/>*/}
				<Container className="app">
					{/* header container */}
					{/*<Row>
						<Col sm={6}>
							Windshield autoglass
						</Col>
						<Col sm={6}>
							Have a quote? Book a service appointment
						</Col>
						<Col>
							<Navbar/>
						</Col>
					</Row> */}
					<Switch>{/* page content container */}
						<Route path="/home">
							<Quote/>
						</Route>
						<Route path="/services">
							<Services/>
						</Route>
						<Route path="/about">
							<About/>
						</Route>
						<Route path="/locations">
							<Locations/>
						</Route>
						<Route path="/quote">
							<Quote/>
						</Route>
						<Route path="/quote-embed">
							<Quote/>
						</Route>
						<Route path="/survey" render={(props) => <Survey {...props} />}>
							<Survey/>
						</Route>
						<Route path="/survey/:leadId" render={(props) => <Survey {...props} />}>
						</Route>
						<Route path="/schedule">
							<Schedule/>
						</Route>
						<Route path="/corporate/quote/approve" render={ (props) =>
							<CorporateQuoteConfirm {...props} action="approve" />
						}>
						</Route>
						<Route path="/corporate/quote/reject" render={ (props) =>
							<CorporateQuoteConfirm {...props} action="reject" />
						}>
						</Route>
						<Route path="/">
							<Quote/>{/* show lead page as default */}
						</Route>
					</Switch>
				</Container>
				{window.location.pathname == "/quote-embed" ? "" : <Footer/>}
			</div>
		</Router>
	);
}

export default App;
