import React, { Component, Fragment } from 'react';

export default class Locations extends Component {

	constructor(props) {
		super(props)
	}
	
	render() {
		return "Locations"
	}
	
}
