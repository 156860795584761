import React from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { CalendarStyleWrapper } from './Calendar.styles';
const Localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);
// const { changeView, changeEvents } = calendarActions;

/*const mapToRBCFormat = e =>
  Object.assign({}, e, {
    start: new Date(e.start),
    end: new Date(e.end),
  });*/

const getIndex = (events, selectedEvent) =>
  events.findIndex(event => event.id === selectedEvent.id);


// for calendar event color coding based on appointment status
const mapStatusIdToColor = (id) => {
	// console.log("mapStatusIdToColor", id);
	switch(id) {
		case -1:
			return ['#999', '#666'];
		case 5:
			return ['#183167', '#132753'];
		case 6:
			return ['#D5A55A', '#d09c49'];
		case 7:
		case 8:
		case 9:
			return ['#C1893D', '#ae7d37'];
		default:
			return ['#54aae8', '#3586e3'];
	}
}


/*
	This uses the event.title field to determine if an event is selected.
	"Available slot"
*/
export default function DndCalendar(props) {
	// console.log("DndCalendar", props);
	
  // const { events, view } = useSelector(state => state.Calendar);
  // const dispatch = useDispatch();
  const [state, setState] = React.useState({
    view: "day",
    modalVisible: false,
    selectedData: undefined,
  });
	
  const onSelectEvent = selectedData => {
	  if(props.onSelect)
		  props.onSelect(selectedData);
    //setState({ ...state, modalVisible: 'update', selectedData });
  };
  const onSelectSlot = selectedData => {
	  if(props.onSelect)
		  props.onSelect(selectedData);
   // setState({ ...state, modalVisible: 'new', selectedData });
  };

  
   /*const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    let allDay = event.allDay;
    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    const updatedEvent = { ...event, start, end, allDay };
    const idx = getIndex(events, updatedEvent);
    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);
    dispatch(changeEvents(nextEvents));

    notification(
      'success',
      'Move event successfully',
      `${event.title} was dropped onto ${event.start}`
    );
  };

  const resizeEvent = ({ event, start, end }) => {
    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });

    dispatch(changeEvents(nextEvents));

    notification(
      'success',
      'Resize event successfully',
      `${event.title} was resized to ${start}-${end}`
    );
  };*/

	// on date change callback
	const onNavigate = (date, view) => {
		//console.log("onNavigate ", date, props.startDate, props.endDate);
		props.onDateChange(
			props.shopid
			, date
			, props.startDate
			, props.endDate
			, view);
	};
	
	  const onView = view => {
		//console.log("onView call from calendar ", view, props.startDate, props.endDate);
		// dispatch(changeView(view));
		
		if(props.onView) 
			props.onView(props.shopid
				, props.token
				, props.date
				, props.startDate
				, props.endDate
				, view);
	  };

  // const setModalData = (type, selectedData) => {
    // const newEvents = [...events];
    // const { modalVisible } = state;
    // if (type === 'cancel') {
      // setState({
        // ...state,
        // modalVisible: false,
        // selectedData: undefined,
      // });
    // } else if (type === 'delete') {
      // const idx = getIndex(newEvents, selectedData);
      // if (idx > -1) {
        // newEvents.splice(idx, 1);
      // }
      // dispatch(changeEvents(newEvents));
      // setState({
        // ...state,
        // modalVisible: false,
        // selectedData: undefined,
      // });
    // } else if (type === 'updateValue') {
      // setState({ ...state, selectedData });
    // } else {
      // if (modalVisible === 'new') {
        // newEvents.push(selectedData);
      // } else {
        // const idx = getIndex(newEvents, selectedData);
        // if (idx > -1) {
          // newEvents[idx] = selectedData;
        // }
      // }
      // dispatch(changeEvents(newEvents));
      // setState({
        // ...state,
        // modalVisible: false,
        // selectedData: undefined,
      // });
    // }
  // };
  
  
    // callback function for on select slot event in calendar, used to get reference to element style
	const eventStyleGetter = function(event, start, end, isSelected) {
		// console.log("eventStyleGetter", event, isSelected);
		let colors = mapStatusIdToColor(event.status_id);
		
		/*var backgroundColor = '#' + event.hexColor;
		var style = {
			backgroundColor: backgroundColor,
			borderRadius: '0px',
			opacity: 0.8,
			color: 'black',
			border: '0px',
			display: 'block'
		};*/
		
		var style = props.mode === "corporate" ? {
			// month view (only for corporate scheduler)
			backgroundColor: !isSelected ? colors[0]: colors[1],
			border: '3px solid black',
			fontSize: '1.1em',
			padding: '20px 8px 40px',
			textAlign: 'center'
		} : props.view === "week" ? {
			// week view
			backgroundColor: !isSelected ? colors[0]: colors[1],
			border: '1px solid #187381',
			padding: '4px 10px',
			borderRadius: '5px'
		} : {
			// day view
			backgroundColor: !isSelected ? colors[0]: colors[1],
			border: '1px solid #187381',
			padding: '4px 30px',
			borderRadius: '5px'
		};
		
		// style appointments specifically based on type
		if(event.title !== "Available slot") {
			style.border = event.is_corporate ? '4px solid #555' : '4px solid #eee';
		}
		
		return {
			style: style
		};
	}
	
	let timeRangeFormat = ( { start, end }, culture, local) => {
		// console.log(start, end, culture, local);
		// console.log(local.format(start, 'hh:mm aa'));
		return local.format(start, 'h:mm a');
	}
	
  return (
    <CalendarStyleWrapper className="isomorphicCalendarWrapper">
      <DragAndDropCalendar
        className="isomorphicCalendar"
		formats={{ eventTimeRangeFormat: timeRangeFormat }}
        selectable
        localizer={Localizer}
        events={ props.events || [] }
        //onEventDrop={moveEvent}
        onEventDrop={ () => false }
        resizable
        //onEventResize={resizeEvent}
        onEventResize={ () => false }
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        onView={onView}
		views={ props.views }
       // defaultView={ props.mode === "corporate" ? "month" : "day" }
        //defaultView={ props.view }
        view={ props.view }
        date={ props.date }
		onNavigate={onNavigate}
		eventPropGetter={eventStyleGetter}
        step={60}
		min={new Date(0, 0, 0, 8, 0, 0)}
		max={new Date(0, 0, 0, 18, 0, 0)}
      />
    </CalendarStyleWrapper>
  );
}

