import axios from "axios";
import config from "../config.js";
import moment from 'moment';

export function getStoreHoursForDateUsingDB(slot) {
	return `${moment().hours(slot.hour_start).format('ha')} to ${moment().hours(slot.hour_end).format('ha')}`;
}


/*
* Approve corporate quote from email link.
*/
export function approveCorporateQuote(params) {
	console.log("approveCorporateQuote", params);
	if(!params.hash || !params.groupId) {
		console.log("approveCorporateQuote missing parameters");
		return;
	}
	axios.post(`${config.API.URL}/api/corporate/leads/group/${params.groupId}/quote/approve/${params.hash}`, {})
		.then(response => {
			console.log("approveCorporateQuote success");
			if(params.callback) params.callback(response, params.groupId);
		})
		.catch(error => {
			console.log("approveCorporateQuote error");
			if(params.callback) params.callback(error, params.groupId);
		});
}


/*
* Reject corporate quote from email link.
*/
export function rejectCorporateQuote(params) {
	console.log("rejectCorporateQuote", params);
	if(!params.hash || !params.groupId) {
		console.log("rejectCorporateQuote missing parameters");
		return;
	}
	axios.post(`${config.API.URL}/api/corporate/leads/group/${params.groupId}/quote/reject/${params.hash}`, {})
		.then(response => {
			console.log("rejectCorporateQuote success");
			if(params.callback) params.callback(response, params.groupId);
		})
		.catch(error => {
			console.log("rejectCorporateQuote error");
			if(params.callback) params.callback(error, params.groupId);
		});
}


/*
* Promo code.
*/

export function attachPromoCode(params) {
	console.log("attachPromoCode", params);
	if(!params.code || !params.id) {
		console.log("attachPromoCode missing parameters");
		return;
	}

	axios.post(`${config.API.URL}/api/promocodes/${params.code}/lead/${params.id}`, params.data)
		.then(response => {
			console.log("attachPromoCode success");
			
			if(params.callback) params.callback(response, params.id);
		})
		.catch(error => {
			console.log("attachPromoCode error");
			console.log(error);
			if(params.callback) params.callback(error, params.id);
		});
}


/*
 * User survey
 */
 
export function getUsersurvey(callback) {
	axios.get(`${config.API.URL}/api/usersurvey`)
		.then(response => {
			console.log("getUsersurvey success");
			
			if(response.data)
				callback(response.data.data);
		})
		.catch(error => {
			console.log("getUsersurvey error");
			console.log(error);
		});
}


export function postUsersurvey(params) {
	axios.post(`${config.API.URL}/api/usersurvey`, params.data)
		.then(response => {
			console.log("postUsersurvey success");
			
			if(params.callback)
				params.callback(response.data);
		})
		.catch(error => {
			console.log("postUsersurvey error");
			console.log(error);
		});
}


/*
* Appointment scheduler.
*/


export function postAppointment(params) {
	console.log("postAppointment", params);
	axios.post(`${config.API.URL}/api/appointments/self/quote/${params.quoteid}/hash/${params.hash}
			/date/${params.date}/timeslot/${params.timeslotid}/shop/${params.shopid}` + (params.package ? `?extra_package=${params.package}` : ""), params.data)
		.then(response => {
			console.log("postAppointment success", response);
			if(params.callback) params.callback(response);
		})
		.catch(error => {
			console.log("postAppointment error", error);
			if(params.callback) params.callback(error);
		});
}


export function getAppointmentsSchedule(params) {
	console.log("getAppointmentsSchedule", params);
	if(!params.shopid || !params.startDate || !params.endDate) {
		
	}
	axios.get(`${config.API.URL}/api/appointments/schedule/shop/${params.shopid}/start/${params.startDate}/end/${params.endDate}`)
		.then(response => {
			console.log("getAppointmentsSchedule success", response);
			if(params.callback) params.callback({ ...response, ...params });
		})
		.catch(error => {
			console.log("getAppointmentsSchedule error", error);
			if(params.callback) params.callback(error);
		});
}


export function getTimeslots(params) {
	console.log("getTimeslots", params);
	axios.get(`${config.API.URL}/api/shops/${params.id}/timeslots`)
		.then(response => {
			console.log("getTimeslots success", response);
			if(params.callback) params.callback(response);
		})
		.catch(error => {
			console.log("getTimeslots error", error);
			if(params.callback) params.callback(error);
		});
}


/*
* Leads 
*/

export function getShops(callback) {
	axios.get(`${config.API.URL}/api/shops`)
		.then(response => {
			console.log("getShops success");
			if(response.data)
				callback(response.data);
		})
		.catch(error => {
			console.log("getShops error");
			console.log(error);
		});
}

/* create a lead */
export function createLead(data, callback) {
	axios.post(`${config.API.URL}/api/leads`, data)
		.then(response => {
			console.log("createLead success", response);
			if(callback) callback(true, response.data);
		})
		.catch(error => {
			console.log("createLead error", error);
			if(error && callback) callback(false, error);
		})
}

/* vehicles */
export function getVehicleYears(callback) {
	axios.get(`${config.API.URL}/api/vehicles/years`)
		.then(response => {
			console.log("getVehicleYears success");
			if(response.data)
				callback(response.data.data);
		})
		.catch(error => {
			console.log("getVehicleYears error");
			console.log(error);
		});
}

export function getVehicleMakes(callback) {
	axios.get(`${config.API.URL}/api/vehicles/makes`)
		.then(response => {
			console.log("getVehicleMakes success");
			if(response.data)
				callback(response.data.data);
		})
		.catch(error => {
			console.log("getVehicleMakes error");
			console.log(error);
		});
}

export function getVehicleModels(makeId, year, callback) {
	axios.get(`${config.API.URL}/api/vehicles/make/${makeId}/year/${year}/models`)
		.then(response => {
			console.log("getVehicleModels success");
			if(response.data)
				callback(response.data.data);
		})
		.catch(error => {
			console.log("getVehicleModels error");
			console.log(error);
		});
}

export function getVehicleTrims(modelId, year, callback) {
	axios.get(`${config.API.URL}/api/vehicles/model/${modelId}/year/${year}/types`)
		.then(response => {
			console.log("getVehicleTrims success");
			if(response.data)
				callback(response.data.data);
		})
		.catch(error => {
			console.log("getVehicleTrims error");
			console.log(error);
		});
}
