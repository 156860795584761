import React, { Component, Fragment } from 'react';

export default class Services extends Component {

	constructor(props) {
		super(props)
	}
	
	render() {
		return "Services"
	}
	
}
