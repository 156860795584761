import React, { Component, Fragment } from 'react';
import {
	Row,
	Col,
	// Nav,
	Form,
	Button
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faCheck, faCheckCircle, faTimesCircle,
			faHome } from '@fortawesome/fontawesome-free-solid';
import Toast from '../components/Toast.js';
import InputMask from 'react-input-mask';
import Expand from 'react-expand-animated';
import { getShops, getVehicleYears, getVehicleMakes, getVehicleModels, getVehicleTrims
		, createLead, attachPromoCode } from "../api/API.js";
import "./Quote.css";
import appConfig from '../config.js';

if (window.location.pathname === "/quote-embed") {
	import("./Quote-Embed.css")
	.then((response) => {
		// console.log("APP EMBED", response);
	});
}

/*function PageHeadingAndBulletpoints() {
	// if this is passed as function argument it will not bind properly at render time -- TODO: fix the binding time

	const props = {
		heading: "Get a quote",
		subheading: "Request a quote in 30 seconds or less.",
		bulletpoints: ["We guarantee our work for life", "We specialize at this too"]
	};

	return (
		<Row>
			<Col>
				<div className="page-heading">{ props.heading }</div>
				<div className="page-subheading">
					{ props.subheading }
				</div>
			</Col>
			<Col>
				<ul className="page-heading-bulletpoints">
				{
					props.bulletpoints && props.bulletpoints.map((val, i) => {
						return <li key={ i }>{ val }</li>
					})
				}
				</ul>
			</Col>
		</Row>
	);
} */

export default class Quote extends Component {

	constructor(props) {
		super(props)

		this.state = {
			contactMethods: [
				{ name: "Email", icon: faEnvelope, img_url: "/img/i-1.png" },
				{ name: "Phone", icon: faPhone, img_url: "/img/i-2.png" }
			],
			contactOptions: [
				{ name: "Yes", caption: "Yes, subscribe me", icon: faCheckCircle, img_url: "/img/i-3.png" },
				{ badge: true, img_url: '/img/i-badge-1.png', name: 'Alberta Owned and Operated'},
				{ name: "No", caption: "No thank you", icon: faTimesCircle, img_url: "/img/i-4.png" }
			],
			repairTypes: [
				{ name: "New Windshield", value: "new windshield", img_url: "/img/i-car-1.png" },
				{ name: "Rock Chip Repair", value: "rock chip", img_url: "/img/i-car-2.png" },
				// { name: "Side Mirror", value: "side mirror", img_url: "/img/i-car-3.png" },
				{ name: "Side Window", value: "side window", img_url: "/img/i-car-5.png" },
				{ name: "Back Glass", value: "back glass", img_url: "/img/i-car-4.png" },
				// { name: "ADAS Calibration", value: "adas calibration", img_url: "/img/i-car-9.png" }
			],
			// test data for shops
			shops: [
				{ id: 1, city: "Calgary", name: "Come to You", address: "", img_url: "/img/i-location-home.png" },
				{ id: 2, city: "Calgary", name: "Calgary Shop", address: "", img_url: "/img/i-location-shop.png" }
			],
			// initialize controlled form inputs to stop warnings
			selectedData: { addressFirstLine: "", addressSecondLine: "", city: "", province: "AB", postalCode: ""
				, firstName: "", lastName: "", email: "", phone: "", promo: "" },
			// isLoading: false,
			shopsLoaded: false,
			// toast related fields
			toastMessage: "",
			toastVariant: "info",
			showToast: false,
			showAddressForm: false,
			showCustomVehicleForm: false,
			modelsLoading: false,
			trimsLoading: false,
			modelFirstEntry: "Model",
			trimsFirstEntry: "Type",
			disableSubmitButton: false,
			promoIsLoading: false
		}

		this.shopsDataCallback = this.shopsDataCallback.bind(this);
		this.toggleCustomVehicleForm = this.toggleCustomVehicleForm.bind(this);
		/* TODO: these could be combined with a parameter to the API call */
		this.vehicleYearsCallback = this.vehicleYearsCallback.bind(this);
		this.vehicleMakesCallback = this.vehicleMakesCallback.bind(this);
		this.vehicleModelsCallback = this.vehicleModelsCallback.bind(this);
		this.vehicleTrimsCallback = this.vehicleTrimsCallback.bind(this);
		this.closeToastCallback = this.closeToastCallback.bind(this);
		this.createLeadCallback = this.createLeadCallback.bind(this);
		this.promoCodeCallback = this.promoCodeCallback.bind(this);
		this.submitPromoCode = this.submitPromoCode.bind(this);
		this.handleSubmitPromo = this.handleSubmitPromo.bind(this);

		// event handlers
		this.handleVehicleYearChange = this.handleVehicleYearChange.bind(this);
		this.handleVehicleMakeChange = this.handleVehicleMakeChange.bind(this);
		this.handleVehicleModelChange = this.handleVehicleModelChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.makeToast = this.makeToast.bind(this);
	}

	async componentDidMount() {
		//this.setState({ isLoading: true});
		getShops(this.shopsDataCallback);
		getVehicleYears(this.vehicleYearsCallback);
		getVehicleMakes(this.vehicleMakesCallback);
		/*await getVehicleModels(this.vehicleModelsCallback);
		await getVehicleTrims(this.vehicleTrimsCallback);*/
	}

	componentWillUnmount() {

	}

	/* generic callbacks for async API requests */
	vehicleYearsCallback(data) {
		//console.log("vehicleYearsCallback", data);
		// reverse vehicle years
		let reversed = data.reverse();
		this.setState({ vehicleYears: reversed });
	}

	vehicleMakesCallback(data) {
		//console.log("vehicleMakesCallback", data);
		this.setState({ vehicleMakes: data });
	}

	vehicleModelsCallback(data) {
		// console.log("vehicleModelsCallback", data);
		let temp = data.length > 0 ? "Model" : "No models found";
		this.setState({ vehicleModels: data, modelsLoading: false, modelFirstEntry: temp });
	}

	vehicleTrimsCallback(data) {
		// console.log("vehicleTrimsCallback", data);
		let temp = data.length > 0 ? "Type" : "No types found";
		this.setState({ vehicleTrims: data, trimsLoading: false, trimsFirstEntry: temp });
	}

	shopsDataCallback(data) {
		// console.log("shopsDataCallback", data);

		let temp = data;
		let index = 1;
		let badgeData = {
			badge: true,
			img_url: '/img/i-badge-2.png',
			name: 'Open 7 Days a Week'
		}
		temp.splice(index, 0, badgeData);

		this.setState({ shops: temp, shopsLoaded: true });
	}

	/* set selected form options */
	selectOption(fieldName, value) {
		// console.log("value", value);
		let data = this.state.selectedData;
		data[fieldName] = value;
		// console.log("selectOption", fieldName, data);
		this.setState({ selectedData: data });

		if (fieldName === "repairType") {
			this.repairTypeCallback(value);
		}
	}

	repairTypeCallback(value) {
		// show/hide vehicle model forms as needed
		if ( value === "new windshield" || value === "side mirror" || value === "back glass" || value === "side window" || value === "adas calibration" ) {
			this.setState({ showVehicleModelForm: true });

			// show/hide feature options as needed
			if (value === "new windshield") {
				this.setState({ showVehicleWindshieldOptions: true, showVehicleSideWindowOptions: false });
			} else if (value === "side window") {
				this.setState({ showVehicleWindshieldOptions: false, showVehicleSideWindowOptions: true });
			} else {
				this.setState({ showVehicleWindshieldOptions: false, showVehicleSideWindowOptions: false });
			}

		} else {
			this.setState({ showVehicleModelForm: false, showVehicleWindshieldOptions: false, showVehicleSideWindowOptions: false });
		}
	}

	toggleCustomVehicleForm() {
		this.setState({ showCustomVehicleForm: !this.state.showCustomVehicleForm });

		// fully reset vehicle form
		this.resetField([0, 1, 2, 3]);
		let temp = this.state.selectedData;
		temp.vehicleYear = "";
		temp.vehicleMake = "";
		temp.vehicleModel = "";
		temp.vehicleTrim = "";
		this.setState({ selectedData: temp });

		// console.log("showCustomVehicleForm", this.state.showCustomVehicleForm);
	}

	/*
		The keys of the fields to reset.
	*/
	resetField(fields) {
		let form = document.getElementById('vehicleForm');
		// console.log("form", form);
		// console.log("fields", fields);
		if(form) {
			fields && fields.forEach(val => {
				form[val].selectedIndex = 0;
			});
		} else {
			console.log("resetField: form not loaded");
		}
	}

	/* change handlers */

	/* map value to selectedData[id] using event data */
	handleChange(e) {
		// console.log("handleChange", e.target.id, e.target.value);
		let temp = this.state.selectedData;
		temp[e.target.id] = e.target.value;
		this.setState({ selectedData: temp });
	}

	handleVehicleYearChange(e) {
		this.handleChange(e);
		let year = e.target.value;
		let yearName = e.target.options[e.target.selectedIndex].getAttribute('data-name');
		let make = this.state.selectedData['vehicleMakeId'];

		this.selectOption("vehicleYear", yearName);
		this.selectOption("vehicleYearId", year);
		// console.log("year", year, yearName);

		// load models

		if(!year || !make) {
			console.log("handleVehicleYearChange: missing year or make");
			return;
		}

		this.setState({ modelsLoading: true });
		getVehicleModels(make, year, this.vehicleModelsCallback);
		this.resetField([1, 2, 3]);
	}

	handleVehicleMakeChange(e) {
		let make = e.target.value;
		let makeName = e.target.options[e.target.selectedIndex].getAttribute('data-name');
		//console.log("handleVehicleMakeChange", make);
		let year = this.state.selectedData['vehicleYearId'];
		if(!year) {
			console.log("invalid year for get models");
			return;
		}
		this.setState({ modelsLoading: true, vehicleTrims: [] });
		getVehicleModels(make, year, this.vehicleModelsCallback);
		this.selectOption("vehicleMake", makeName);
		this.selectOption("vehicleMakeId", make);
		this.resetField([2, 3]);
	}

	handleVehicleModelChange(e) {
		let year = this.state.selectedData['vehicleYearId'];
		let modelId = e.target.value;
		let modelName = e.target.options[e.target.selectedIndex].getAttribute('data-name')
		// console.log("modelId", modelId);

		if(isNaN(parseInt(modelId))) {
			console.log("model id is not a number");
			this.setState({ vehicleTrims: [] });
			return;
		}

		this.setState({ trimsLoading: true });
		getVehicleTrims(modelId, year, this.vehicleTrimsCallback);
		this.selectOption("vehicleModel", modelName);
		this.selectOption("vehicleModelId", modelId);

		this.resetField([3]);
		//console.log(modelName);
	}

	// handlePromoModalChange(e) {
		// console.log("handlePromoModalChange", e);
	// }

	handleSubmitPromo(e) {
		e.preventDefault();
		e.persist();
		console.log("handleSubmitPromo", e);
		this.setState({ promoIsLoading: true });
		this.submitPromoCode(e.target[0] && e.target[0].value, e.target.id, this.promoCodeCallback);
	}

	promoCodeCallback(response, id) {
		// console.log("promoCodeCallback", response);
		if(response.status === 200) {
			this.makeToast("Your quote request has been submitted", "success"
				, 0, () => { return <Button variant="primary" size="lg" className="backButton" href="https://boulevardautoglass.com">
										<FontAwesomeIcon icon={ faHome } className="faIcon" />
										Back to Homepage
									</Button> }
				, this.completionMessage());
		} else {
			// this.setState({ promoIsLoading: false });
			// console.log("promo toast", response);
			// failed, update modal
			this.makeToast("Your quote request has been submitted", "success"
				, 0, () => { return <Button variant="primary" size="lg" className="backButton" href="https://boulevardautoglass.com">
										<FontAwesomeIcon icon={ faHome } className="faIcon" />
										Back to Homepage
									</Button> }
				, this.completionMessage(response)
				, <Form action="#" id={id} onSubmit={ this.handleSubmitPromo }>
					<Form.Label>
						Please enter your promotion code again
					</Form.Label>
					<Form.Control id="promoModal"
						as="input"
						// onChange={ this.handlePromoModalChange }
					/>
					<Button type="submit" variant="secondary" disabled={ this.state.promoIsLoading }>
						Submit Code
					</Button>
				</Form>);
		}
	}

	completionMessage(response) {
		return (
			<div key="promo" className="text-left bold">
				You will receive an email within the next 30 minutes during business hours or by 9am the next day outside of business hours.<br/>
				<div className="text-center mt-4 mb-2 times">
					<span className="timeHours">Monday to Friday &nbsp; 8 am - 6 pm</span>
					<span className="timeHours">Saturday &nbsp; 9 am - 5 pm</span>
					<span className="timeHours">Sunday &nbsp; 9 am - 3 pm</span>
				</div>
				<div className="text-center">
					Winter Hours
				</div>
				<div className="text-center mt-2 mb-4 times">
					<span className="timeHours">Saturday &nbsp; 9 am - 3 pm</span>
					<span className="timeHours">Sunday &nbsp; 9 am - 1 pm</span>
				</div>
				<div className="text-center">
					Please check your spam folder if it has not shown up after an hour.
				</div>
				{
					response ? (<>
						<br/>
						<div className="text-center promoError">
							<h5>There was an error with your promotion code</h5>
							{
								response.response && response.response.data && response.response.data.error
							}
						</div>
					</>) : ""
				}
				<img className="watermark" src='/img/logo-full.png'/>
			</div>
		);
	}

	submitPromoCode(code, id, callback) {
		// console.log("submitPromoCode", code, id, callback);
		let params = {
			code: code,
			id: id,
			callback: callback
		};
		attachPromoCode(params);
	}


	createLeadCallback(success, data) {
		console.log("createLeadCallback", success, data);
		if(success) {

			// window.location.reload();
			this.setState({ disableSubmitButton: true });
			// make call to attach promo code
			let promo = this.state.selectedData['promo'];
			if(promo) {
				this.submitPromoCode(promo, data && data.data && data.data.id, this.promoCodeCallback);
			} else {
				// console.log("no promo code");
				this.makeToast("Your quote request has been submitted", "success"
					, 0, () => { return (
									<Button variant="primary" size="md" href="https://boulevardautoglass.com">
										<FontAwesomeIcon icon={ faHome } className="faBackIcon" />
										Back to Homepage
									</Button>
								) }
					, this.completionMessage());
			}
		} else {
			if(data.response && data.response.data && data.response.data.error)
				this.makeToast(Object.values(data.response.data.error).toString(), "failure");
			else
				this.makeToast("There was an error submitting your request. Please try again later.", "info");
		}
	}

	// boundFunction = this.createLeadCallback.bind(this);

	handleSubmitQuote(e) {
		// console.log("submit");
		e.preventDefault();
		//validate
		// console.log(this.state.selectedData);

		let isLocal = appConfig.API.URL === "http://windshield.test";

		/*if(!this.state.selectedData['shop']) {
			this.makeToast("Please select a shop", "info");
			let row = document.getElementById("locationsOptions");
			row && row.scrollIntoView();
			return;
		}*/

		if(!this.state.selectedData['repairType']) {
			this.makeToast("Please indicate the type of repair", "info");
			let row = document.getElementById("repairTypes");
			row && row.scrollIntoView();
			return;
		}

		/*if(!this.state.selectedData['contactOption']) {
			this.makeToast("Please indicate if you want to subscribe", "info");
			let row = document.getElementById("contactMeOption");
			row && row.scrollIntoView();
			return;
		}*/

		if(isLocal) {
			//
		} else if(this.state.showVehicleModelForm && this.state.selectedData['repairType'] !== "rock chip"
				&& (!this.state.selectedData['vehicleMakeId']
				|| !this.state.selectedData['vehicleModelId']
				|| !this.state.selectedData['vehicleYear']) ) {
			this.makeToast("Please fill out all vehicle information", "info");
			let row = document.getElementById("vehicleInfo");
			row && row.scrollIntoView();
			return;
		}

		if(this.state.showVehicleModelForm && this.state.selectedData['repairType'] !== "rock chip"
				&& (!this.state.selectedData['vehicleMake']
				|| !this.state.selectedData['vehicleModel']
				|| !this.state.selectedData['vehicleYear']) ) {
			this.makeToast("Please fill out all vehicle information", "info");
			let row = document.getElementById("vehicleInfo");
			row && row.scrollIntoView();
			return;
		}

		/*if(this.state.showVehicleWindshieldOptions
				&& (!this.state.selectedData['rainSensor']
				|| !this.state.selectedData['forwardCollision']
				|| !this.state.selectedData['heated']) ) {
			this.makeToast("Please fill out all vehicle details", "info");
			let row = document.getElementById("option-rainSensor");
			row && row.scrollIntoView();
			return;
		}*/

		if(this.state.showVehicleSideWindowOptions
				&& (!this.state.selectedData['typeSide']) ) {
			this.makeToast("Please fill out all vehicle details", "info");
			let row = document.getElementById("option-sideGlass");
			row && row.scrollIntoView();
			return;
		}

		if(!this.state.selectedData['firstName']
				|| !this.state.selectedData['lastName']) {
			this.makeToast("Please enter your first and last name", "info");
			let row = document.getElementById("personalInfo");
			row && row.scrollIntoView();
			return;
		}

		// email and phone validation is dumb at the moment, it just checks for content
		// note: check react-phone-number-input for built-in phone validation
		if(this.state.selectedData['contactMethod'] === "Email" && !this.state.selectedData['email']) {
			this.makeToast("Please enter your email address", "info");
			let row = document.getElementById("personalInfo");
			row && row.scrollIntoView();
			return;
		}

		if(this.state.selectedData['contactMethod'] === "Phone"
				&& (!this.state.selectedData['phone'])) {
			this.makeToast("Please enter your phone number", "info");
			let row = document.getElementById("personalInfo");
			row && row.scrollIntoView();
			return;
		}

		if(!this.state.selectedData['contactMethod']) {
			this.makeToast("Please choose a contact method", "info");
			let row = document.getElementById("contactMethods");
			row && row.scrollIntoView();
			return;
		}

		// validate address if the 'come to you' option is selected
		/*if(this.state.showAddressForm
				&& (!this.state.selectedData['addressFirstLine']
				|| !this.state.selectedData['city']
				|| !this.state.selectedData['postalCode'])) {
			this.makeToast("Please fill out your address including the city and postal code", "info");
			let row = document.getElementById("addressForm");
			row && row.scrollIntoView();
			return;
		}*/

		// package the data
		let data = {
			first_name: this.state.selectedData['firstName'],
			last_name: this.state.selectedData['lastName'],
			// address_1: this.state.selectedData['addressFirstLine'],
			// address_2: this.state.selectedData['addressSecondLine'],
			// city: this.state.selectedData['city'],
			// postal_code: this.state.selectedData['postalCode'],
			contact_method: this.state.selectedData['contactMethod'],
			email: this.state.selectedData['email'],
			phone: this.state.selectedData['phone'],
			work_needed: this.state.selectedData['repairType'],
			// vehicle_make: this.state.selectedData['vehicleMake'] || isLocal ? "Hot Wheels" : "",
			// vehicle_model: this.state.selectedData['vehicleModel'] || isLocal ? "Model T" : "",
			// vehicle_type: this.state.selectedData['vehicleTrim'] || isLocal ? "Type A" : "",
			// vehicle_year: this.state.selectedData['vehicleYear'] || isLocal ? 1989 : 1950,
			vehicle_make: this.state.selectedData['vehicleMake'] || "",
			vehicle_model: this.state.selectedData['vehicleModel'] || "",
			vehicle_type: this.state.selectedData['vehicleTrim'] || "",
			vehicle_year: this.state.selectedData['vehicleYear'] || 1950,
			// contact_optin: this.state.selectedData['contactOption'],
			// shop_id: this.state.selectedData['shop'] || 2,
			/*
			* MAN-260, always use "come to us" option from now on
			*/
			shop_id: 2,
			comments: this.state.selectedData['comments']
		}

		/*if (this.state.showVehicleWindshieldOptions) {
			data.window_feature_rain_sensor = this.state.selectedData['rainSensor'];
			data.window_feature_lane_departure = this.state.selectedData['forwardCollision'];
			data.window_feature_heated = this.state.selectedData['heated'];
		}*/
		if (this.state.showVehicleSideWindowOptions) {
			data.window_feature_type_side = this.state.selectedData['typeSide'];
		}

		// patch in store address data
		if(!this.state.showAddressForm) {
			// linear search the shops - ugly
			var tempShop;
			for(var i = 1; i < this.state.shops.length; i++) {
				if(this.state.shops[i].id === data.shop_id) {
					// console.log("store match");
					tempShop = this.state.shops[i];
					break;
				}
			}
			if(tempShop) {
				data['address_1'] = tempShop.address;
				data['city'] = tempShop.city;
				data['postal_code'] = "T2H 1Z5";
			}
			//console.log("shops", this.state.shops);
		}

		// console.log(data);
		createLead(data, this.createLeadCallback);
	}

	/* check for "come to me" option and display address form */
	updateShop(shopid) {
		// console.log('updateShop', shopid);
		if(shopid === 1) {
			this.setState({ showAddressForm: true });
		} else {
			this.setState({ showAddressForm: false });
		}
	}

	makeToast(msg, variant, time, button, label, action) {
		// console.log("making toast", msg, variant, time, button, action);
		this.setState({ toastMessage: msg, toastVariant: variant, toastTime: time
			, toastButton: button, showToast: true, label: label
			, action: action });
	}

	closeToastCallback() {
		// console.log("closeToastCallback");
		this.setState({ showToast: false, toastMessage: "" });
	}

	render() {

		/*const headingProps = {
			heading: "Get a quote",
			subheading: "Request a quote in 30 seconds or less.",
			bulletpoints: ["We guarantee our work for life", "We specialize at this too"]
		};*/
		//console.log("this.state.showToast", this.state.showToast);
		const expandTransition = ["height", "opacity"];

		return (
			<Fragment>{ this.state.shopsLoaded ?
				<div id="LeadForm">
					<Toast
						key={ this.state.toastMessage }
						show={ this.state.showToast }
						callback={ this.closeToastCallback }
						message={ this.state.toastMessage }
						variant={ this.state.toastVariant }
						time={ this.state.toastTime }
						button={ this.state.toastButton }
						label={ this.state.label }
						action={ this.state.action }
					/>
					{/*<PageHeadingAndBulletpoints props={ headingProps }/>*/}
					{window.location.pathname === "/quote-embed" ? "" :
					<Row>
						<Col className="mx-4 mt-4 text-center">
							<img className="float-left" src="/img/logo-full.png" alt="Boulevard Auto Glass" />
							<h1>Get a Quote</h1>
							<Button variant="primary" size="lg" className="backButton" href="https://boulevardautoglass.com">
								<FontAwesomeIcon icon={ faHome } className="faIcon" />
								Back to Homepage
							</Button>
						</Col>
					</Row>
					}
					{/*<Row>{*locations*}
						<Col id="locationsOptions">
							<div className="section-heading">Locations</div>
							<div className="section-subheading">Choose a Location:</div>
							<Row>{*location tile container*}
								{ this.buildLocationTiles() }
							</Row>
						</Col>
					</Row>*/}
					{/*<Expand open={this.state.showAddressForm} duration={500} transition={expandTransition}>
						<Row> {* address form *}
							<Col id="addressForm">
								<Fragment>
									<div className="section-subheading">Where would you like to have the vehicle worked on?</div>
									{ this.buildAddressEntryForm() }
								</Fragment>
							</Col>
						</Row>
					</Expand>*/}
					<Row>{/* repair type */}
						<Col id="repairTypes">
							<div className="section-heading">Type of Repair</div>
							<div className="section-subheading">What glass is in need of repair?</div>
							<Row>{/* repair type option container */}
								{ this.buildRepairTypeTiles() }
							</Row>
						</Col>
					</Row>
					<Expand open={this.state.showVehicleModelForm} duration={500} transition={expandTransition}>
						<Row>{/* vehicle information */}
							<Col id="vehicleInfo">
								<div className="section-heading">Vehicle Information</div>
								<div className="section-subheading">What do you drive?</div>
								<div className="mx-4">{/* vehicle make model container */}
									{ this.buildVehicleModelForm() }
								</div>
							</Col>
						</Row>
					</Expand>
					{/*
					<Expand open={this.state.showVehicleWindshieldOptions} duration={500} transition={expandTransition}>
						<div className="vehicle-options">
							<Row id="option-rainSensor">{/* rain sensor
								<Col>
									<div className="section-heading">Rain / Condensation Sensor</div>
									<div className="section-subheading">If your windshield wipers turn on automatically when it rains, you have a rain sensor and should select "yes".</div>
									<Row>
										<Col sm={7}>
											<img src="/img/i-car-7.png" alt="Rain Sensor Diagram" className="w-100"/>
										</Col>
										<Col sm={5}>
										{
											this.buildYesNoButtonForm(this.state.selectedData['rainSensor'], (e) => {
												//console.log("setting rainsensor ", e.target.value);
												this.selectOption("rainSensor", e.target.value);
											})
										}
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>{/* forward collision warning
								<Col>
									<div className="section-heading">Forward Collision Warning / Lane Departure</div>
									<div className="section-subheading">Select “yes” if your car has a mechanism that detects when the car is moving outside of its respective lane (before the turning signal is turned on) in order to prevent collisions.</div>
									<Row>
										<Col sm={7}>
											<img src="/img/i-car-6.png" alt="Forward Collision Warning Diagram" className="w-100"/>
										</Col>
										<Col sm={5}>
										{
											this.buildYesNoButtonForm(this.state.selectedData['forwardCollision'], (e) => {
												//console.log("setting forwardCollision ", e.target.value);
												this.selectOption("forwardCollision", e.target.value);
											})
										}
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>{/* heated windshield
								<Col>
									<div className="section-heading">Heated</div>
									<div className="section-subheading">If your windshield is heated throughout or has a heated wiper park area, please select "yes".</div>
									<Row>
										<Col sm={7}>
											<img src="/img/i-car-8.png" alt="Heated Windshield Diagram" className="w-100"/>
										</Col>
										<Col sm={5}>
										{
											this.buildYesNoButtonForm(this.state.selectedData['heated'], (e) => {
												//console.log("setting heated ", e.target.value);
												this.selectOption("heated", e.target.value);
											})
										}
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Expand>
					*/}
					<Expand open={this.state.showVehicleSideWindowOptions} duration={500} transition={expandTransition}>
						<div className="vehicle-options">
							<Row id="option-sideGlass">{/* side glass */}
								<Col>
									<div className="section-heading">Type of Side Glass</div>
									{/*<div className="section-subheading">Select the type of side glass.</div>*/}
									<Row>
										<Col sm={7}>
											<img src="/img/i-car-10.png" alt="Side Window Diagram" className="white-bg w-100"/>
										</Col>
										<Col sm={5}>
											<Form.Control as="select" onChange={ (e) => {
													console.log("setting typeSide ", e.target.value);
													this.selectOption("typeSide", e.target.value);
												} }>
												<option value="-1">Choose an option</option>
												<option value="Driver Front Vent">Driver Front Vent</option>
												<option value="Driver Front Door">Driver Front Door</option>
												<option value="Driver Rear Door">Driver Rear Door</option>
												<option value="Driver Rear Vent">Driver Rear Vent</option>
												<option value="Driver Quarter">Driver Quarter</option>
												<option value="Passenger Front Vent">Passenger Front Vent</option>
												<option value="Passenger Front Door">Passenger Front Door</option>
												<option value="Passenger Rear Door">Passenger Rear Door</option>
												<option value="Passenger Rear Vent">Passenger Rear Vent</option>
												<option value="Passenger Quarter">Passenger Quarter</option>
											</Form.Control>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Expand>
					{/* <Row>newsletter / promo
						<Col id="contactMeOption">
							<div className="section-heading">Signup to our newsletter?</div>
							<Row>{/* promotion option container
								{ this.buildPromoTiles() }
							</Row>
						</Col>
					</Row>
					*/}
					<Row>{/* personal info */}
						<Col id="personalInfo">
							<div className="section-heading">Personal Information</div>
							<div className="section-subheading">We will need to contact you about your appointment:</div>
							<Form className="row">
								<Form.Group controlId="firstName" className="col-md-6">
									<Form.Control type="text" placeholder="First Name" value={ this.state.firstName } onChange={ this.handleChange } />
								</Form.Group>
								<Form.Group controlId="lastName" className="col-md-6">
									<Form.Control type="text" placeholder="Last Name" value={ this.state.lastName } onChange={ this.handleChange } />
								</Form.Group>
								<Form.Group controlId="email" className="col-md-6">
									<Form.Control type="email" placeholder="Email" value={ this.state.email } onChange={ this.handleChange } />
								</Form.Group>
								<Form.Group className="col-md-6">
									<InputMask id="phone" className="form-control" placeholder="Phone Number" mask="999-999-9999" value={ this.state.selectedData.phone } onChange={this.handleChange} />
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row>{/* contact */}
						<Col id="contactMethods">
							{/*<div className="section-heading">Contact</div>*/}
							<div className="section-subheading">How would you like to be contacted?</div>
							<Row>{/* contact option tile container */}
								{ this.buildContactTiles() }
							</Row>
						</Col>
					</Row>
					<Row>{/* promo code */}
						<Col>
							<div className="section-heading">Promotion Code</div>
							<div className="mx-4 mb-4">
								<Form.Group controlId="promo">
									<Form.Control as="input" className="promoCodeInput" value={ this.state.selectedData['promo'] } onChange={ this.handleChange } />
								</Form.Group>
							</div>
						</Col>
					</Row>
					<Row>{/* comments etc. */}
						<Col>
							<div className="section-heading">Any other questions or comments:</div>
							<div className="mx-4 mb-5">
								<Form.Group controlId="comments">
									<Form.Control as="textarea" rows="6" value={ this.state.selectedData['comments'] } onChange={ this.handleChange } />
								</Form.Group>
								<Button className="getQuoteSubmitButton" disabled={ this.state.disableSubmitButton }
									variant="primary" onClick={ (e) => this.handleSubmitQuote(e) }>Get a Quote!</Button>
							</div>
						</Col>
					</Row>
				</div>
			:
				<div>Loading...</div>
			}</Fragment>
		);
	}

	buildAddressEntryForm() {
		return (
			<Form className="mx-4">
				<Form.Group controlId="addressFirstLine">
					<Form.Control type="text" placeholder="Street address" value={ this.state.selectedData['addressFirstLine'] } onChange={ this.handleChange } />
				</Form.Group>
				<Form.Group controlId="addressSecondLine">
					<Form.Control type="text" placeholder="Address line 2 (optional)" value={ this.state.selectedData['addressSecondLine'] } onChange={ this.handleChange } />
				</Form.Group>
				<Form.Group controlId="city">
					<Form.Control as="select" onChange={ this.handleChange }>
						<option value={null}>City</option>
						<option value="Calgary">Calgary</option>
						<option value="Airdrie">Airdrie</option>
						<option value="Okotoks">Okotoks</option>
					</Form.Control>
				</Form.Group>
				<Form.Group controlId="province">
					<Form.Control as="select" onChange={ this.handleChange } disabled>
						<option value="AB">Alberta</option>
						<option value="BC">British Columbia</option>
						<option value="MB">Manitoba</option>
						<option value="NB">New Brunswick</option>
						<option value="NL">Newfoundland and Labrador</option>
						<option value="NS">Nova Scotia</option>
						<option value="ON">Ontario</option>
						<option value="PE">Prince Edward Island</option>
						<option value="QC">Quebec</option>
						<option value="SK">Saskatchewan</option>
						<option value="NT">Northwest Territories</option>
						<option value="NU">Nunavut</option>
						<option value="YT">Yukon</option>
					</Form.Control>
				</Form.Group>
				<Form.Group controlId="postalCode">
					<InputMask id="postalCode" className="form-control" placeholder="Postal Code" mask="*** ***" value={ this.state.selectedData.postalCode } onChange={ this.handleChange } />
				</Form.Group>
			</Form>
		);
	}

	buildYesNoButtonForm(currentValue, callback) {
		return (
			<Form>
				<Form.Group className="yesno-button-form">
					<Button variant="info" value="yes" onClick={ callback } active={ currentValue === 'yes' }>Yes</Button>
					<Button variant="info" value="no" onClick={ callback } active={ currentValue === 'no' } >No</Button>
					<Button variant="info" value="unsure" onClick={ callback } active={ currentValue === 'unsure' } >Unsure</Button>
				</Form.Group>
			</Form>
		)
	}

	buildVehicleModelForm() {
		const expandTransition = ["height", "opacity"];
		return (
			<>
				<Expand open={!this.state.showCustomVehicleForm} duration={500} transition={expandTransition}>
					<Form id="vehicleForm" className="vehicle-form">
						<Form.Group controlId="vehicleYear">
							<Form.Control as="select" onChange={ this.handleVehicleYearChange }>
								<option value={ null } key="0">Year</option>
								{
									this.state.vehicleYears && this.state.vehicleYears.map((val, i) => {
										return (
											<option value={ val.id } data-name={ val.year_number } key={ i }>{ val.year_number }</option>
										)
									})
								}
							</Form.Control>
						</Form.Group>
						<Form.Group controlId="vehicleMake">
							<Form.Control as="select" onChange={ this.handleVehicleMakeChange }>
								<option value={ null } key="0">Make</option>
								{
									this.state.vehicleMakes && this.state.vehicleMakes.map((val, i) => {
										//console.log(val);
										return (
											<option value={ val.id } key={ i } data-name={ val.name }>{ val.name }</option>
										)
									})
								}
							</Form.Control>
						</Form.Group>
						<Form.Group controlId="vehicleModel">
							<Form.Control as="select" onChange={ this.handleVehicleModelChange }>
								<option value={ null } key="0">
								{
									this.state.modelsLoading ? "Loading models..." : this.state.modelFirstEntry
								}
								</option>
								{
									this.state.vehicleModels && this.state.vehicleModels.map((val, i) => {
										//console.log(val);
										return (
											<option value={ val.id } key={ i } data-name={ val.model }>{ val.model }</option>
										)
									})
								}
							</Form.Control>
						</Form.Group>
						<Form.Group controlId="vehicleTrim">
							<Form.Control as="select" onChange={ this.handleChange }>
								<option value={ null } key="0">
								{
									this.state.trimsLoading ? "Loading types..." : this.state.trimsFirstEntry
								}
								</option>
								{
									this.state.vehicleTrims && this.state.vehicleTrims.map((val, i) => {
										//console.log(val);
										return (
											<option value={ val.type } key={ i }>{ val.type }</option>
										)
									})
								}
							</Form.Control>
						</Form.Group>
						<Col>
							<p><span className="customVehicleLink" onClick={this.toggleCustomVehicleForm}>If you are unsure, or can't find your vehicle in the lists above, click here.</span></p>
						</Col>
					</Form>
				</Expand>
				<Expand open={this.state.showCustomVehicleForm} duration={500} transition={expandTransition}>
					<Form className="vehicle-form">
						<Form.Group>
							<Col>
								<p><span className="customVehicleLink" onClick={this.toggleCustomVehicleForm}>Go back to vehicle picker.</span></p>
							</Col>
						</Form.Group>
						<Form.Group>
							<InputMask id="vehicleYearCustom" className="form-control" placeholder="Year" mask="9999" value={ this.state.selectedData.vehicleYear || "" } onChange={ this.handleChange } />
						</Form.Group>
						<Form.Group controlId="vehicleMakeCustom">
							<Form.Control type="text" placeholder="Make" value={ this.state.selectedData.vehicleMake || "" } onChange={ this.handleChange } />
						</Form.Group>
						<Form.Group controlId="vehicleModelCustom">
							<Form.Control type="text" placeholder="Model" value={ this.state.selectedData.vehicleModel || "" } onChange={ this.handleChange } />
						</Form.Group>
					</Form>
				</Expand>
			</>
		)
	}

	buildLocationTiles() {
		return (
			<div className="fieldset-indent">
				<ul className="list">
					{this.state.shops && this.state.shops.map((val, i) => {
						//console.log(val);
						return (
							<Fragment key={ i }>
								{val.badge ?
									<li className={"col-lg-3 col-md-4 col-sm-4 list-item location-option"}>
										<div className="w-100 h-100" style={{'display':'flex'}}>
											<img className="img-fluid m-auto" src={val.img_url} alt={val.name} />
										</div>
									</li>
								:
									<li className={"col-lg-3 col-md-4 col-sm-4 list-item location-option" + (this.state.selectedData.shop === val.id ? " selected" : "")}>
										<label htmlFor={"shop_"+i}>
											<div className="bg-image" style={{ 'backgroundImage': 'url('+val.img_url+')' }}>
												<img src="/img/blank.png" width="200" height="150" alt={val.name} />
											</div>
											<input type="radio" data-shop={ val.name } name={"LeadForm[shop_id]"} id={"shop_"+i} value={i} onClick={ () => {
												this.selectOption('shop', val.id);
												this.updateShop(val.id);
											} }/>
											<span><strong>{ val.city }</strong><br/>{ val.name }<br/>{ val.address }</span>
											<div className="selected"><FontAwesomeIcon icon={faCheck} /></div>
										</label>
									</li>
								}
							</Fragment>
						)
					})}
				</ul>
			</div>
		)
	}

	buildContactTiles() {
		return (
			<div className="fieldset-indent">
				<ul className="list contactOptions">
					{this.state.contactMethods && this.state.contactMethods.map((val, i) => {
						//console.log(val);
						return (
							<li key={ i } className={"col-sm-3 list-item contact-option" + (this.state.selectedData.contactMethod === val.name.toLowerCase() ? " selected" : "")}>
								<label htmlFor={"contact_"+i}>
									<div className="bg-image" style={{ 'backgroundImage': 'url('+val.img_url+')' }}>
										<img src="/img/blank.png" width="100" height="50" alt={val.name} />
									</div>
									<input type="radio" data-shop={ val.name } name={"LeadForm[contact_method]"} id={"contact_"+i} value={i}
										onClick={this.selectOption.bind(this, 'contactMethod', val.name.toLowerCase())}/>
									<span><FontAwesomeIcon icon={ val.icon } /> { val.name }</span>
									<div className="selected"><FontAwesomeIcon icon={faCheck} /></div>
								</label>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}

	buildRepairTypeTiles() {
		return (
			<div className="fieldset-indent">
				<ul className="list">
					{this.state.repairTypes && this.state.repairTypes.map((val, i) => {
						//console.log(val);
						return (
							<li key={ i } className={"col-lg-3 col-md-6 list-item repair-option" + (this.state.selectedData.repairType === val.value.toLowerCase() ? " selected" : "")}>
								<label htmlFor={"repair_"+i}>
									<div className="bg-image" style={{ 'backgroundImage': 'url('+val.img_url+')' }}>
										<img src="/img/blank-alt.png" width="400" height="174" alt={val.name} />
									</div>
									<input type="radio" data-shop={ val.name } name={"LeadForm[repair_id]"} id={"repair_"+i} value={i} onClick={ () => {
										this.selectOption('repairType', val.value.toLowerCase())
									} } />
									<span>{ val.name }</span>
									<div className="selected"><FontAwesomeIcon icon={faCheck} /></div>
								</label>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}

	buildPromoTiles() {
		return (
			<div className="fieldset-indent">
				<ul className="list">
					{this.state.contactOptions && this.state.contactOptions.map((val, i) => {
						//console.log(val);
						return (
							<Fragment key={ i }>
								{val.badge ?
									<li className={"col-lg-3 col-md-4 col-sm-4 list-item promo-option"}>
										<div className="w-100 h-100" style={{'display':'flex'}}>
											<img className="img-fluid m-auto" src={val.img_url} alt={val.name} />
										</div>
									</li>
								:
								<li className={"col-lg-3 col-md-4 col-sm-4 list-item promo-option" + (this.state.selectedData.contactOption === val.name.toLowerCase() ? " selected" : "")}>
									<label htmlFor={"promo_"+i}>
										<div className="bg-image" style={{ 'backgroundImage': 'url('+val.img_url+')' }}>
											<img src="/img/blank.png" width="200" height="150" alt={val.name} />
										</div>
										<input type="radio" data-shop={ val.name } name={"LeadForm[promo_id]"} id={"promo_"+i} value={i}
											onClick={this.selectOption.bind(this, 'contactOption', val.name.toLowerCase())} />
										<span><FontAwesomeIcon icon={ val.icon } /> { val.caption }</span>
										<div className="selected"><FontAwesomeIcon icon={faCheck} /></div>
									</label>
								</li>}
							</Fragment>
						)
					})}
				</ul>
			</div>
		)
	}
}
