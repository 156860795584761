import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
	Container,
	Row,
	Col,
	Form,
	Button
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faTimesCircle } from '@fortawesome/fontawesome-free-solid';
import Toast from '../components/Toast.js';
import Appointments from './Appointments.js';
import moment from 'moment';
import queryString from 'query-string'
import { postAppointment, getAppointmentsSchedule, getShops } from "../api/API.js";
import "./Schedule.css";

// copied from Quote.js - I assume it's important
if (window.location.pathname === "/quote-embed") {
	import("./Quote-Embed.css")
	.then((response) => {
		// console.log("APP EMBED", response);
	});
}


class Schedule extends Component {

	
	constructor(props) {
		super(props)
		this.state = {
			isLoading: true,
			shops: [],
			appointments: [],
			toastParams: {},
			date: new Date(),
			query: {}
		}
		
		this.getAppointmentsCallback = this.getAppointmentsCallback.bind(this);
		this.getShopsCallback = this.getShopsCallback.bind(this);
	}
	
	componentDidMount() {
		// this.setState({ isLoading: true });
		getShops(this.getShopsCallback);
		
		const values = queryString.parse(this.props.location.search)
		this.setState({ query: values });
		
		// get schedule for available date if it's set
		let startDate = this.state.date;
		let available = values['available'];

		
		if(values['available']) {
			let m1 = moment(available);
			let m2 = moment(startDate);
			
			if(m2.isBefore(m1)) {
				startDate = available;
			}
		} else {
			// default available date to previous day
			values['available'] = moment().subtract(1, 'd').format('YYYY-MM-DD');
			// console.log("setting default");
		}
		
		let endDate = moment(startDate).add(1, 'd').format('YYYY-MM-DD');
		
		let params = {
			date: startDate,
			shopid: values.shop_id,
			startDate: moment(startDate).format("YYYY-MM-DD"),
			endDate: moment(endDate).format("YYYY-MM-DD"),
			callback: this.getAppointmentsCallback
		};
		
		getAppointmentsSchedule(params);
	}

	componentWillUnmount() {

	}
	
	getShopsCallback(data) {
		// console.log("getShopsCallback", data);
		if(data) this.setState({ shops: data });
	}
	
	getAppointmentsCallback(response) {
		// console.log("getAppointmentsCallback", response);
		let appointmentsData = [];
		if(response.status === 200) {
			if(response.data && response.data.data) {
				
				let earliestDate = (moment(this.state.date).format("YYYY-MM-DD") < this.state.query['available']) ?
									 moment(this.state.query['available']).toDate() : response.currentDate;

				this.setState({ appointments: response.data.data, shopid: response.shopid, date: earliestDate });
				// this.setState({ shopid: params.shopid,  });
			} else {
				console.log("No data returned by getAppointments");
			}
		} else if(response.status >= 400) {
			// show error message
			let toastParams = {
				message: "Error getting available appointment slots - please refresh page or try again later.",
				variant: "failure",
				show: true
			};
			this.setState({ toastParams: toastParams });
		}
	}
	
	render(props) {
		let values = this.state.query;
		// console.log("values['available']", values['available']);
		// console.log("moment(this.state.date).format(YYYY-MM-DD)", moment(this.state.date).format("YYYY-MM-DD"));
		if(moment(this.state.date).format("YYYY-MM-DD") < values['available']) {
			// alert(moment(values['available']).toDate());
			this.setState({ date: moment(values['available']).toDate() });
		} else {
			
		}
		
		let appointmentsProps = {
			getAppointmentsSchedule: (params) => {
				// shopid bubbles up to this parent container on change
				
				getAppointmentsSchedule({ ...params, callback: this.getAppointmentsCallback });
			},
			// appointments: this.state.appointments,
			shops: this.state.shops,
			shopid: this.state.shopid,
			date: this.state.date,
			startDate: new Date(),
			endDate: new Date(),
			query: this.state.query,
		};
		
		// console.log("render appointmentsProps",  (this.state.appointments
					// && Object.keys(this.state.appointments)[0])
					// + (this.state.shops && this.state.shops.length + "")
					// + (this.state.shopid + "" ) );
		
		const key = this.state.appointments && Object.keys(this.state.appointments)[0]
					+ "-" + Object.keys(this.state.appointments).length
					+ "-" + (this.state.shops && this.state.shops.length)
					+ "-" + (this.state.shopid + "" )
					+ "-" + moment(this.state.date).toString();
					
		// console.log("key", key);
		return (
			<Container className="scheduleContainer" key={key}>
				<Row>
					<Col>
					{/*<Toast { ...this.state.toastParams }/>*/}
					</Col>
				</Row>
				<Row>
					<Col className="text-center">
						<img className="float-left" src="/img/logo-full.png" alt="Boulevard Auto Glass" />
						<h1>Schedule an Appointment</h1>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col className="text-center">
						<Appointments key={key} appointments={ this.state.appointments } { ...appointmentsProps } />
					</Col>
				</Row>
			</Container>
		)
	}
}


export default withRouter(Schedule);
