import React, { Component, Fragment } from 'react';

export default class Home extends Component {

	constructor(props) {
		super(props)
	}
	
	render() {
		return "home"
	}
	
}
