import React, { Component } from 'react';
import './Toast.css';

export default class Toast extends Component {
	
	toastTimeout;
	
	constructor(props) {
		super(props)
		// console.log("building toast");
		
		this.state = {
			message: props.message || '',
			variant: props.variant || 'info',
			modalClass: props.button ? 'modalToast' : '',
			type: props.type || 'floating',
			time: props.time === 0 ? 86400000 : (props.time || 5000), // default time
			// time: props.time || 5000, // default time
			show: props.show || false,
			button: props.button,
			label: props.label,
			callback: props.callback,
			action: props.action
		};

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		
		
		if(props.show) this.updateShow();
	}
	
	
	updateState(props) {
		// console.log("updateState", props);
		if(props.message !== this.state.message || props.show !== this.state.show) {
			this.setState({
				message: props.message || '',
				variant: props.variant || 'info',
				// type: props.button ? 'modal' : (props.type || 'floating'),
				type: props.type || 'floating',
				modalClass: props.button ? 'modalToast' : '',
				time: props.time === 0 ? 86400000 : (props.time || 5000), // default time
				// time: props.time || 5000, // default time
				show: props.show || false,
				button: props.button,
				label: props.label,
				callback: props.callback,
				action: props.action
			});
			if(props.show) this.updateShow();	
		}
	}
	
	// componentWillReceiveProps(props) {
		// console.log("componentWillReceiveProps", props);
		// this.updateState(props);
	// }
	
	componentDidUpdate(props) {
		// console.log("componentDidUpdate", props, this.state);
		if(props.show !== this.state.show
				|| props.message !== this.state.message) {
			this.updateState(props);
		}
		
		
	}
	
	componentDidMount() {
		//console.log("toast mounted");
	}
	
	componentWillUnmount() {
		if(this.toastTimeout) clearTimeout(this.toastTimeout);
		// console.log("componentWillUnmount");
		// call parent to clear toast
		// this.state.callback();
	}
	
	// set hide timeout for toast
	updateShow() {
		// console.log('updateShow');
		if(this.toastTimeout) clearTimeout(this.toastTimeout);
		this.toastTimeout = setTimeout(() => {
			this.hide();
		}, this.state.time);
	}
	
	show() {
		this.setState({ show: true });
	}
	
	hide() {
		this.setState({ show: false });
		// console.log("HIDE");
		if(this.state.callback) this.state.callback();
	}
	
	render() {
		let button = this.state.button && this.state.button();
		// console.log("rendering toast ", button);
		return this.state.show === true ? (
			<div className="toastWrapper">
				<div id="toastContent" className={ `toastStyle ${this.state.variant} ${this.state.type} ${this.state.modalClass}` }>
					<span className="toastMessage">{ this.state.message }</span>
					{ this.state.label ? <div className="toastLabel">{ this.state.label }</div> : "" }
					{ this.state.action ? <div className="toastAction">{ this.state.action }</div> : "" }
					<div className="mainToastButton">{ button }</div>
				</div>
			</div>
		) : ""
	}
}
